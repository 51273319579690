import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class BusinessInformationService {
    /**
     * Retrieves business information by provided businessInfoId.
     * @function getBusinessInformation
     * @async
     * @param {string} businessInfoId
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async getBusinessInformation(businessInfoId) {
        try {
            const response = await axios.get(
                `/Customer/BusinessInformation/Get?id=${businessInfoId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Gets the default business information
     * @function getDefaultBusinessInformation
     * @async
     * @returns  {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async getDefaultBusinessInformation() {
        const res = await BusinessInformationService.getAllBusinessInformations();
        if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].isDefault) {
                    return await BusinessInformationService.getBusinessInformation(res.data[i].id);
                }
            }
        }

        throw new Error('Unable to find default business information');
    }

    /**
     * Sends GET request to get all business information entities
     * @function getAllBusinessInformations
     * @async
     * @returns  {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async getAllBusinessInformations() {
        try {
            const response = await axios.get(
                "/Customer/BusinessInformation/GetAll"
            );

            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Deletes the business information by provided businessInfoId.
     * @function deleteBusinessInformation
     * @async
     * @param {string} businessInfoId
     * @returns  {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async deleteBusinessInformation(businessInfoId) {
        try {
            const response = await axios.delete(
                `/Customer/BusinessInformation/Delete?id=${businessInfoId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends POST FORM request to API to create new Business Information Entity
     * @function createBusinessInformation
     * @async
     * @param {object} businessInfo
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async createBusinessInformation(businessInfo) {
        delete businessInfo.files;

        try {
            const response = await axios.postForm(
                "/Customer/BusinessInformation/Create",
                businessInfo
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends PUT FORM request to API to update provided Business Information Entity
     * @function updateBusinessInformation
     * @async
     * @param {object} businessInfo
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async updateBusinessInformation(businessInfo) {
        delete businessInfo.files;
        delete businessInfo.file;

        try {
            const response = await axios.putForm(
                "/Customer/BusinessInformation/Update",
                businessInfo
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends PUT FORM request to API to swap the logoFile on the provided Id
     * @function updateBusinessInformation
     * @async
     * @param {object} logoFile
     * @param {string} id
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async swapBusinessInformationLogo(logoFile, id) {
        try {
            const response = await axios.putForm(
                `/Customer/BusinessInformation/SwapLogo`,
                {
                    businessInformationId: id,
                    logoFile,
                }
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
