import React, { useContext, useEffect, useState } from "react";
import PermissionExpansionPanel from "./Components/PermissionExpansionPanel.jsx";
import BaseService from "../../../../../../services/BaseService.js";
import BaseContent from "./Components/Content/BaseContent.jsx";
import { RoleContext } from "../../Providers/RoleProvider.jsx";
import PermissionsHelper from "../../../../../../resources/PermissionsHelper.js";

/**
 * @function PermissionSelection
 * @return {Element}
 * @constructor
 */
const PermissionSelection = () => {
    const { permissions } = useContext(RoleContext);
    const [groups, setGroups] = useState(null);

    useEffect(() => {
        const service = new BaseService("Authentication/PermissionGroup");
        service.getAll().then((res) => {
            setGroups(res.data);
        });
    }, []);

    const hasPermission = (permission) => {
        if (!permission) return false;
        return PermissionsHelper.hasPermission(permissions, [permission], []);
    };

    const formatChildGroups = (children) => {
        return children.map((child) => {
            const formattedPermissions = child.permissions.map((permission) => {
                return {
                    id: permission.id,
                    name: permission.name,
                    hasPermission: hasPermission(permission.id),
                };
            });

            const formattedChild = {
                id: child.id,
                name: child.name,
                fullAccess: false,
            };

            formattedPermissions.forEach((permission) => {
                formattedChild[permission.name.toLowerCase()] = {
                    hasPermission: permission.hasPermission,
                    id: permission.id,
                };
            });

            if (formattedPermissions.every((i) => i.hasPermission)) {
                formattedChild.fullAccess = true;
            } else if (formattedPermissions.some((i) => i.hasPermission)) {
                formattedChild.fullAccess = null;
            }

            return formattedChild;
        });
    };

    return (
        groups && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: 10,
                    width: "100%",
                }}
            >
                {groups.map((group) => {
                    const childGroups = formatChildGroups(group.children);
                    const columnsSet = new Set();

                    childGroups.forEach((group) => {
                        const keys = Object.entries(group).filter(([key]) => {
                            if (key === "fullAccess") return false;
                            return typeof group[key]?.hasPermission === "boolean";
                        });

                        keys.forEach(([key]) => {
                            columnsSet.add(key);
                        });
                    });

                    const orderedColumns = [...columnsSet].sort((a, b) => {
                        // Determines the order of the base permissions, change this up if you don't like it
                        // You can also add permissions to this list if so desired
                        const basePermissions = [
                            "view",
                            "create",
                            "edit",
                            "delete",
                        ];

                        // Get index of each permission name
                        // -1 if value is not a base permission
                        const indexA = basePermissions.indexOf(a.toLowerCase());
                        const indexB = basePermissions.indexOf(b.toLowerCase());

                        // Sort by order in array if both are base permissions
                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }

                        // Ensure A comes first if base permission
                        if (indexA !== -1) {
                            return -1;
                        }

                        // Ensure B comes first if base permission
                        if (indexB !== -1) {
                            return 1;
                        }

                        // Maintain original order if neither are base permissions
                        return 0;
                    });

                    return (
                        <PermissionExpansionPanel
                            key={group.id}
                            title={group.name}
                            accessList={{}}
                            panelContent={
                                <BaseContent
                                    childPermissionGroups={childGroups}
                                    columns={orderedColumns}
                                />
                            }
                        />
                    );
                })}
            </div>
        )
    );
};

export default PermissionSelection;
