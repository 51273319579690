import React, { useContext } from "react";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";

const TransferBtn = ({ dataItem }) => {
    const paymentProvider = useContext(PaymentContext);

    return (
        <div>
            {dataItem.remainder.amount === dataItem.total.amount && (
                <ActionButton
                    requiredPermissions={[PermissionsEnum.EditCustomerPayments]}
                    onClick={() =>
                        paymentProvider.setTransferringPaymentId(dataItem.id)
                    }
                >
                    Transfer
                </ActionButton>
            )}
        </div>
    );
};

export default TransferBtn;
