import React, { useContext } from "react";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import GeneralTaskActionModal from "./GeneralTaskActionModal";
import { YesNoBooleanCell } from "../../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { deleteTask } from "../../../../../services/Deprecated/calendar/taskServices";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const GeneralTaskGridModal = (props) => {
    const { close, visible } = props;
    const generalTaskActionModal = useModal();
    const { triggerReload } = useContext(ReloadDataContext);

    const onDeleteClick = (id) => {
        deleteTask(id).then(() => {
            triggerReload();
        });
    };

    return (
        <>
            {visible && (
                <ResponsiveDialog
                    title={"General Tasks"}
                    onClose={() => close()}
                    size={"medium"}
                >
                    <span
                        className={"JustifyLeftAlignLeft FlexColumn MediumGap"}
                    >
                        <GeneralTaskActionModal {...generalTaskActionModal} />
                        <ActionButton
                            onClick={() => generalTaskActionModal.open()}
                            requiredPermissions={[PermissionsEnum.CreateTask]}
                            text={"Create"}
                            icon={"k-icon k-i-plus"}
                        />
                        <DataGrid
                            uri={"/Customer/Task/GetAllGeneralTasks?"}
                            style={{
                                minHeight: "500px",
                            }}
                        >
                            <GridNoRecords>
                                No General Tasks have been found
                            </GridNoRecords>
                            <GridColumn field="name" title="Name" />
                            <GridColumn
                                field="isAutoAssigned"
                                title="Is Auto Assigned"
                                cell={YesNoBooleanCell}
                                width={200}
                            />
                            <GridColumn
                                width={125}
                                filterable={false}
                                sortable={false}
                                cell={(props) => (
                                    <ActionCell
                                        onDelete={() =>
                                            onDeleteClick(props.dataItem.id)
                                        }
                                        onEdit={() =>
                                            generalTaskActionModal.open(
                                                props.dataItem.id
                                            )
                                        }
                                        permissions={{
                                            edit: [PermissionsEnum.EditTask],
                                            delete: [
                                                PermissionsEnum.DeleteTask,
                                            ],
                                        }}
                                    />
                                )}
                            />
                        </DataGrid>
                    </span>
                </ResponsiveDialog>
            )}
        </>
    );
};

export default GeneralTaskGridModal;
