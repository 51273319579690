import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { deleteLocation } from "../../../../../services/Deprecated/humanResources/locationServices";
import { LocationModal } from "./Components/LocationModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const LocationDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <LocationModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add Location"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateCompanyLocation]}
            />
            <DataGrid
                uri={"/HumanResources/Location/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteLocation(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.EditCompanyLocation],
                                delete: [PermissionsEnum.DeleteCompanyLocation],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default LocationDashboardPage;
