import * as React from "react";
import { useContext } from "react";
import styles from "./MyWorkAdminPanel.module.scss";
import { useHREmployees } from "../../../../../hooks/Deprecated/useHREmployees";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MyWorkContext } from "../../MyWorkContext";
import FormField from "../../../../../components/Deprecated/StylingField";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import PermissionsHelper from "../../../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../../../hooks/useUserPermissions.js";

export const MyWorkAdminPanel = () => {
    const { setCurrentEmployee, setIsAdminPanelVisible, currentEmployee } =
        useContext(MyWorkContext);
    const hasAdminPermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        [PermissionsEnum.ClockInAsAnyTimeClock]
    );
    const { employees } = useHREmployees(hasAdminPermission);

    return (
        <span className={styles.MyWorkAdminPanel}>
            <p className={styles.Header}>Admin Panel</p>
            <hr className={"Separator"} />
            <span className={"JustifyCenterAlignLeft SmallGap FlexColumn"}>
                <span>
                    <FormField
                        label="Selected Employee:"
                        value={currentEmployee.fullName}
                    />
                </span>
            </span>
            <p className={styles.SubHeader + " AlignTextLeft"}>
                Administrative Options:
            </p>
            <span>
                <ActionButton
                    text={"Exit Admin Panel"}
                    requiredPermissions={[
                        PermissionsEnum.ClockInAsAnyTimeClock,
                    ]}
                    onClick={() => setIsAdminPanelVisible(false)}
                    style={{
                        width: "100%",
                        borderRadius: 10,
                        fontSize: "1.2rem",
                    }}
                />
                <DropDownList
                    className={styles.EmployeeAdminListSelection}
                    data={employees}
                    defaultValue={{
                        employeeId: 0,
                        fullName: "Change Selected Employee",
                    }}
                    dataItemKey={"id"}
                    textField={"fullName"}
                    onChange={(e) => {
                        setCurrentEmployee(e.value);
                        setIsAdminPanelVisible(false);
                    }}
                />
            </span>
        </span>
    );
};
