import React, { useContext, useEffect } from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteVendor } from "../../../services/Deprecated/inventory/vendorService";
import { VendorContext } from "../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import VendorDocumentProvider from "../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import vendorEditForm from "./Components/VendorEditForm";
import VendorTabs from "./Components/VendorTabs";
import VendorItemsGrid from "./Components/VendorItemsGrid";
import VendorPOsGrid from "./Components/VendorPOsGrid";
import VendorIRsGrid from "./Components/VendorIRsGrid";
import VendorBillsGridWrapper from "./Components/VendorBillsGrid";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider.jsx";
import { VendorFiles } from "./Components/VendorFiles.jsx";

const VendorDetailsPageWrapper = (props) => {
    return (
        <VendorDocumentProvider>
            <VendorDetailsPage {...props} />
        </VendorDocumentProvider>
    );
};

const VendorDetailsPage = (props) => {
    const vendorContext = useContext(VendorContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const vendorId = props?.match?.params?.id;

    useEffect(() => {
        vendorContext.setVendorId(vendorId);
    }, [vendorId]);

    const onVendorDelete = (vendorId) => {
        deleteVendor(vendorId).then(() => {
            handleRouteChange({
                uri: "/Inventory/Vendor",
                breadcrumbInfo: [],
            });
        });
    };

    return vendorContext.vendor ? (
        <div>
            <header className="vendor-name-header">
                {vendorContext.vendor.name}
            </header>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card
                        className="option-card-container"
                        style={{ border: "none" }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <ActionButtonsCommandCell
                                        record={vendorContext.vendor}
                                        dataId={vendorId}
                                        action={onVendorDelete}
                                        EditComponent={vendorEditForm}
                                        requiredPermissionsEdit={[
                                            PermissionsEnum.EditVendor,
                                        ]}
                                        requiredPermissionsAction={[
                                            PermissionsEnum.DeleteVendor,
                                        ]}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <div className="tab-container">
                        <Card
                            className="card-container"
                            style={{ border: "none" }}
                        >
                            <VendorTabs />
                        </Card>
                    </div>
                </div>
            </div>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card className="card-container" style={{ border: "none" }}>
                        <HighlightedTabStrip>
                            <TabStripTab title="Items">
                                <VendorItemsGrid vendorId={vendorId} />
                            </TabStripTab>
                            <TabStripTab title="Purchase Orders">
                                <VendorPOsGrid />
                            </TabStripTab>
                            <TabStripTab title="PO Item Receipts">
                                <VendorIRsGrid />
                            </TabStripTab>
                            <TabStripTab title="Bills">
                                <VendorBillsGridWrapper />
                            </TabStripTab>
                            <TabStripTab title="Files">
                                <VendorFiles vendorId={vendorId} />
                            </TabStripTab>
                        </HighlightedTabStrip>
                    </Card>
                </div>
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default VendorDetailsPageWrapper;
