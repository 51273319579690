import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormRemoteDropDown } from "../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../resources/Deprecated/formValidators.js";

export const EachReceival = (props) => {
    const { record, isUndo } = props;

    const generateReceiveMessage = () => {
        let initMsg;

        if (!isUndo) {
            initMsg = "Do you wish to receive ";
        } else {
            initMsg = "Do you wish to undo the receival of ";
        }

        return (
            <p>
                {initMsg}
                <strong style={{ textDecoration: "underline" }}>
                    {Math.abs(record.currentReceived)}
                </strong>{" "}
                {record.item.unitItem?.name ? record.item.unitItem.name : record.item.name}?
            </p>
        );
    };

    return (
        <span>
            {generateReceiveMessage()}
            <hr className={"Separator"} />
            {!isUndo && (
                <Field
                    url={"/Inventory/StorageLocation/GetAll?"}
                    name={"storageLocation"}
                    component={FormRemoteDropDown}
                    label={"Storage Location of Item(s):"}
                    validator={requiredValidator}
                    style={{ width: "100%" }}
                />
            )}
        </span>
    );
};
