import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import ItemInformationForm from "./ItemInformationForm";
import clone from "just-clone";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import {
    getItem,
    updateItem,
} from "../../../../services/Deprecated/inventory/itemService";
import { getVendorItemsByItemId } from "../../../../services/Deprecated/inventory/vendorService";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const ItemEditForm = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const [initialFormValues, setInitialFormValues] = useState(null);
    const { triggerReload } = useContext(ReloadDataContext);

    /**
     * Function that will be called when the user submits the item form.
     * @param {Object} formData - Contains the data from the form.
     * @returns {Promise<void>}
     */
    const submitItem = async (formData) => {
        toggleLoaderVisible();

        const submissionData = clone(formData);

        submissionData.categoryId = submissionData?.category?.id;
        submissionData.itemTypeId = submissionData?.itemType?.id;
        submissionData.orderingUomId = submissionData?.orderingUom?.id;
        submissionData.retailUomId = submissionData?.retailUom?.id;
        submissionData.incomeAccountId = submissionData.incomeAccount?.id;
        submissionData.expenseAccountId = submissionData.expenseAccount?.id;
        submissionData.retailPrice = {
            amount: submissionData.retailPrice,
            currency: "USD",
        };
        submissionData.unitItemId = submissionData?.unitItem?.id;

        const serviceResponse = await updateItem(submissionData);

        if (serviceResponse.success) {
            resetForm();
        }
        toggleLoaderVisible();
    };

    useEffect(() => {
        if (!visible) return;

        getItem(record.id).then((res) => {
            const item = res.data;
            item.retailPrice = item.retailPrice?.amount;

            // Fetch vendors for the item
            getVendorItemsByItemId(record.id).then((vendorRes) => {
                // Assign vendors to the item
                item.vendors = vendorRes.data;
                setInitialFormValues(item);
            });
        });
    }, [visible, record]);

    const resetForm = () => {
        setInitialFormValues(null);

        // trigger reload on Cancel or Update
        // because the dialogue now supports changes to vendor cost
        // which do not require the user to click "Update"
        // but need to be reflected in the grid after an edit.
        triggerReload();

        onCancel();
    };

    return (
        <>
            {visible && initialFormValues && (
                <Form
                    initialValues={initialFormValues}
                    onSubmit={submitItem}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Item"}
                            onClose={resetForm}
                            size={"medium"}
                        >
                            <FormElement>
                                <ItemInformationForm
                                    itemCount={"single"}
                                    item={initialFormValues}
                                    formRenderProps={formRenderProps}
                                    setInitialFormValues={setInitialFormValues}
                                />
                                <FormButtons
                                    actionOnCancel={resetForm}
                                    loaderVisible={loaderVisible}
                                    text={"Update"}
                                    allowSubmit={formRenderProps.allowSubmit}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </>
    );
};

export default ItemEditForm;
