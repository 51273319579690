export const PermissionsEnum = {
    ViewRole: "01000000-0000-0000-0000-000000000150",
    CreateRole: "01000000-0000-0000-0000-000000000149",
    EditRole: "01000000-0000-0000-0000-000000000151",
    DeleteRole: "01000000-0000-0000-0000-000000000152",
    SetDefaultRole: "48887026-1582-480F-9FAD-32B88A2B1065",
    ViewUser: "01000000-0000-0000-0000-000000000146",
    EditUser: "01000000-0000-0000-0000-000000000147",
    ViewBusinessInformation: "01000000-0000-0000-0000-000000000014",
    CreateBusinessInformation: "01000000-0000-0000-0000-000000000013",
    EditBusinessInformation: "01000000-0000-0000-0000-000000000015",
    DeleteBusinessInformation: "01000000-0000-0000-0000-000000000016",
    ViewTask: "01000000-0000-0000-0000-000000000050",
    CreateTask: "01000000-0000-0000-0000-000000000049",
    EditTask: "01000000-0000-0000-0000-000000000051",
    DeleteTask: "01000000-0000-0000-0000-000000000052",
    ViewTaskCategory: "01000000-0000-0000-0000-000000000046",
    CreateTaskCategory: "01000000-0000-0000-0000-000000000045",
    EditTaskCategory: "01000000-0000-0000-0000-000000000047",
    DeleteTaskCategory: "01000000-0000-0000-0000-000000000048",
    ViewUnitOfMeasure: "01000000-0000-0000-0000-000000000094",
    CreateUnitOfMeasure: "01000000-0000-0000-0000-000000000093",
    EditUnitOfMeasure: "01000000-0000-0000-0000-000000000095",
    DeleteUnitOfMeasure: "01000000-0000-0000-0000-000000000096",
    ViewPayroll: "10C5C763-DCFA-4DC2-89CE-E2F26D51FFBB",
    GeneratePayroll: "0FB70DD4-D302-4046-91FF-746996CD9B14",
    SetIntervalPayroll: "B1B07ABA-E189-4DD7-81BE-B6DC845E9BB7",
    ViewIdentificationType: "01000000-0000-0000-0000-000000000030",
    CreateIdentificationType: "01000000-0000-0000-0000-000000000029",
    EditIdentificationType: "01000000-0000-0000-0000-000000000031",
    DeleteIdentificationType: "01000000-0000-0000-0000-000000000032",
    ViewLaborType: "01000000-0000-0000-0000-000000000038",
    CreateLaborType: "01000000-0000-0000-0000-000000000037",
    EditLaborType: "01000000-0000-0000-0000-000000000039",
    DeleteLaborType: "01000000-0000-0000-0000-000000000040",
    ViewCommunicationType: "01000000-0000-0000-0000-000000000209",
    CreateCommunicationType: "01000000-0000-0000-0000-000000000210",
    EditCommunicationType: "01000000-0000-0000-0000-000000000211",
    DeleteCommunicationType: "01000000-0000-0000-0000-000000000212",
    ViewSource: "01000000-0000-0000-0000-000000000201",
    CreateSource: "01000000-0000-0000-0000-000000000202",
    EditSource: "01000000-0000-0000-0000-000000000203",
    DeleteSource: "01000000-0000-0000-0000-000000000204",
    ViewEventType: "CCE25EDD-8541-43DE-A93C-02784F5BFA99",
    CreateEventType: "95F0CD36-6E42-401F-9370-5C58AE332AAB",
    EditEventType: "39FCF476-26B4-415B-8EF2-AE50AA9DD45D",
    DeleteEventType: "C2F99600-AE89-49E9-8D7D-0A98BC6E1E15",
    ViewCompanyLocation: "01000000-0000-0000-0000-000000000026",
    CreateCompanyLocation: "01000000-0000-0000-0000-000000000025",
    EditCompanyLocation: "01000000-0000-0000-0000-000000000027",
    DeleteCompanyLocation: "01000000-0000-0000-0000-000000000028",
    ViewMaritalStatus: "01000000-0000-0000-0000-000000000034",
    CreateMaritalStatus: "01000000-0000-0000-0000-000000000033",
    EditMaritalStatus: "01000000-0000-0000-0000-000000000035",
    DeleteMaritalStatus: "01000000-0000-0000-0000-000000000036",
    ViewBillingTerm: "01000000-0000-0000-0000-000000000090",
    CreateBillingTerm: "01000000-0000-0000-0000-000000000089",
    EditBillingTerm: "01000000-0000-0000-0000-000000000091",
    DeleteBillingTerm: "01000000-0000-0000-0000-000000000092",
    ViewItemType: "01000000-0000-0000-0000-000000000058",
    CreateItemType: "01000000-0000-0000-0000-000000000057",
    EditItemType: "01000000-0000-0000-0000-000000000059",
    DeleteItemType: "01000000-0000-0000-0000-000000000060",
    ViewStorageLocation: "E887B9AB-9F72-4B9D-99FF-8D11B0FD63A4",
    CreateStorageLocation: "69446473-77D3-42FB-9C6E-ABBE231D0980",
    EditStorageLocation: "4B585F82-B625-40F5-98F4-FDF6BA2281EC",
    DeleteStorageLocation: "D637D6B3-B5FB-42FF-9889-FBE5D3B118E5",
    ViewPhoneType: "01000000-0000-0000-0000-000000000002",
    CreatePhoneType: "01000000-0000-0000-0000-000000000001",
    EditPhoneType: "01000000-0000-0000-0000-000000000003",
    DeletePhoneType: "01000000-0000-0000-0000-000000000004",
    ViewPaymentType: "01000000-0000-0000-0000-000000000010",
    CreatePaymentType: "01000000-0000-0000-0000-000000000009",
    EditPaymentType: "01000000-0000-0000-0000-000000000011",
    DeletePaymentType: "01000000-0000-0000-0000-000000000012",
    ViewSocialMedia: "01000000-0000-0000-0000-000000000006",
    CreateSocialMedia: "01000000-0000-0000-0000-000000000005",
    EditSocialMedia: "01000000-0000-0000-0000-000000000007",
    DeleteSocialMedia: "01000000-0000-0000-0000-000000000008",
    ViewFileAccess: "3D3E2EB1-93D1-4C29-A27D-66618C644526",
    CreateFileAccess: "B3B3461A-B18D-438B-A998-C516D90BA61F",
    DeleteFileAccess: "5A095FD4-35D5-4E73-A591-2013946E3443",
    ViewAccount: "01000000-0000-0000-0000-000000000158",
    CreateAccount: "01000000-0000-0000-0000-000000000157",
    EditAccount: "01000000-0000-0000-0000-000000000159",
    DeleteAccount: "01000000-0000-0000-0000-000000000160",
    ViewTransaction: "01000000-0000-0000-0000-000000000162",
    CreateTransaction: "01000000-0000-0000-0000-000000000161",
    DeleteTransaction: "01000000-0000-0000-0000-000000000164",
    ReconcileTransaction: "01000000-0000-0000-0000-000000001165",
    ViewBalanceSheet: "C2821206-37C8-419A-BF47-80988C6C8646",
    ViewIncomeStatement: "0D6D035D-F06E-4C9C-AF37-D5BA79B0DAA7",
    ViewCustomerPayments: "01000000-0000-0000-0000-000000000130",
    CreateCustomerPayments: "01000000-0000-0000-0000-000000000129",
    EditCustomerPayments: "01000000-0000-0000-0000-000000000131",
    DeleteCustomerPayments: "01000000-0000-0000-0000-000000000132",
    DisburseCustomerPayments: "01000000-0000-0000-0000-000000001133",
    ViewJobPayments: "C1694B38-7778-4AEE-8409-81BF5115FCE1",
    CreateJobPayments: "176E22CE-EB54-478E-B7F4-EA5C638AF4F9",
    EditJobPayments: "E04BE843-EF8A-46F1-B8C6-FF84399DEE4A",
    DeleteJobPayments: "B421BEF9-5FCD-45F9-A1B5-0FDFD946BF1F",
    DisburseJobPayments: "86611860-6026-4117-9166-407032F88CB8",
    ViewVendorPayments: "01000000-0000-0000-0000-000000000102",
    CreateVendorPayments: "01000000-0000-0000-0000-000000000101",
    EditVendorPayments: "01000000-0000-0000-0000-000000000103",
    DeleteVendorPayments: "01000000-0000-0000-0000-000000000104",
    ViewEmployee: "01000000-0000-0000-0000-000000000018",
    CreateEmployee: "01000000-0000-0000-0000-000000000017",
    EditEmployee: "01000000-0000-0000-0000-000000000019",
    DeleteEmployee: "01000000-0000-0000-0000-000000000020",
    ViewSSNEmployee: "01000000-0000-0000-0000-000000001121",
    ViewTimeClock: "01000000-0000-0000-0000-000000000195",
    CreateTimeClock: "01000000-0000-0000-0000-000000000194",
    EditTimeClock: "01000000-0000-0000-0000-000000000196",
    DeleteTimeClock: "01000000-0000-0000-0000-000000000197",
    ActAsStationaryTimeClock: "01000000-0000-0000-0000-000000001198",
    ClockInAsAnyTimeClock: "063AFE6C-C6E5-4AA8-AA9A-810DCDD3D8F3",
    ViewItem: "01000000-0000-0000-0000-000000000086",
    CreateItem: "01000000-0000-0000-0000-000000000085",
    EditItem: "01000000-0000-0000-0000-000000000087",
    DeleteItem: "01000000-0000-0000-0000-000000000088",
    ViewCategory: "01000000-0000-0000-0000-000000000062",
    CreateCategory: "01000000-0000-0000-0000-000000000061",
    EditCategory: "01000000-0000-0000-0000-000000000063",
    DeleteCategory: "01000000-0000-0000-0000-000000000064",
    ViewCustomization: "01000000-0000-0000-0000-000000000054",
    CreateCustomization: "01000000-0000-0000-0000-000000000053",
    EditCustomization: "01000000-0000-0000-0000-000000000055",
    DeleteCustomization: "01000000-0000-0000-0000-000000000056",
    ViewInventory: "B2D2BBA2-8CA4-4E27-A4C1-6F748BE3A7CE",
    CreateInventory: "0DA7EEF6-6522-4DB2-BFB8-7B2B9329C351",
    EditInventory: "3F75AA3C-CD13-4540-A584-4B907CCD309E",
    DeleteInventory: "582D8EF6-B435-4BAD-9EB4-CACC2283F66C",
    ReceivalInventory: "32FEDEE4-7FF7-45A1-84E0-50E911E63A0C",
    AllocationInventory: "2601FF99-10D3-47AA-B45D-F217BB1F1941",
    ViewVendor: "01000000-0000-0000-0000-000000000066",
    CreateVendor: "01000000-0000-0000-0000-000000000065",
    EditVendor: "01000000-0000-0000-0000-000000000067",
    DeleteVendor: "01000000-0000-0000-0000-000000000068",
    ViewVendorDocument: "01000000-0000-0000-0000-000000000078",
    CreateVendorDocument: "01000000-0000-0000-0000-000000000077",
    EditVendorDocument: "01000000-0000-0000-0000-000000000079",
    DeleteVendorDocument: "01000000-0000-0000-0000-000000000080",
    ViewBillingAdjustment: "C7E77215-D658-44C2-857B-4D6694337B39",
    CreateBillingAdjustment: "76C78CEF-91FD-4433-A91C-804608510790",
    EditBillingAdjustment: "1524EAEB-859A-46A9-87FF-61414D89C645",
    DeleteBillingAdjustment: "C3D13CF9-313C-4395-922D-70540EEB6A4F",
    ViewReminders: "01000000-0000-0000-0000-000000000126",
    CreateReminders: "01000000-0000-0000-0000-000000000125",
    EditReminders: "01000000-0000-0000-0000-000000000127",
    DeleteReminders: "01000000-0000-0000-0000-000000000128",
    ViewAllReminders: "01000000-0000-0000-0000-000000001129",
    ViewOutstandingItemReceipts: "ACEAE2D2-409E-4308-8429-BA730BDBC8B6",
    ViewCurrentActiveTasks: "B90F856A-711C-43D3-906F-2669DED12222",
    ViewTrafficReport: "01000000-0000-0000-0000-000000000193",
    ViewBalanceDueNoFutureEvents: "01000000-0000-0000-0000-000000000200",
    ViewNoBalanceDueNoFutureEvents: "A34DFA59-FF5F-427C-A84C-CA5D6B382A07",
    ViewEventDatesQueue: "97564D2C-BFC9-416B-A5FC-79281EF55EDD",
    ViewServiceDatesQueue: "F0964AB1-DAB2-4646-A796-48665B583ED1",
    ViewPayrollExport: "41D73598-E982-4202-B3B9-0C3379310185",
    EventManagement: "CBB1ADDE-D611-4777-9BCC-E37FCE9E7A91",
    ViewScheduler: "502116C6-A3E4-4363-AE56-80EB2D56E0DF",
    CalendarEvent: "58074DB9-2142-4BCA-A9E2-329944AD95A4",
    ViewCalendarEvent: "3BB52E9F-E156-4473-A6C2-3843F648662C",
    CreateCalendarEvent: "B16E931A-4921-429D-9BB6-3F97694B8129",
    EditCalendarEvent: "535C4043-8727-4B16-9BCE-6D986AA55E08",
    DeleteCalendarEvent: "0E8124D2-9B02-45BB-B9AF-08623B179E5B",
    ViewCustomer: "01000000-0000-0000-0000-000000000106",
    CreateCustomer: "01000000-0000-0000-0000-000000000105",
    EditCustomer: "01000000-0000-0000-0000-000000000107",
    DeleteCustomer: "01000000-0000-0000-0000-000000000108",
    ViewLead: "01000000-0000-0000-0000-000000000138",
    CreateLead: "01000000-0000-0000-0000-000000000137",
    EditLead: "01000000-0000-0000-0000-000000000139",
    DeleteLead: "01000000-0000-0000-0000-000000000140",
    ConvertToCustomerLead: "01000000-0000-0000-0000-000000001141",
    ViewJob: "01000000-0000-0000-0000-000000000118",
    CreateJob: "01000000-0000-0000-0000-000000000117",
    EditJob: "01000000-0000-0000-0000-000000000119",
    DeleteJob: "01000000-0000-0000-0000-000000000120",
    EditSalesOrderJob: "B19F864A-C638-4A70-B05E-3F8CCCF455CE",
    ViewJobDocument: "01000000-0000-0000-0000-000000000114",
    CreateJobDocument: "01000000-0000-0000-0000-000000000113",
    EditJobDocument: "01000000-0000-0000-0000-000000000115",
    DeleteJobDocument: "01000000-0000-0000-0000-000000000116",
};
