import React, { useContext, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { GridColumn } from "@progress/kendo-react-grid";
import EventNoteCreate from "./EventNoteCreate";
import EventNoteEdit from "./EventNoteEdit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import styles from "../EventDetailsPage.module.scss";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteEventNote } from "../../../../services/Deprecated/customer/eventServices";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { DataGrid } from "../../../../components/Grids/DataGrid";

dayjs.extend(utc);
dayjs.extend(timezone);

const EventNotesPortlet = (props) => {
    const [reloadData, setReloadData] = useState({});
    const triggerReload = () => setReloadData(Math.random());
    const eventId = props?.match?.params?.id;
    const eventContext = useContext(EventContext);

    const formatDate = (dateString) => {
        const date = dayjs.utc(dateString).local();
        return date.isValid()
            ? date.format("MM/DD/YYYY h:mm:ss A")
            : "Invalid Date";
    };

    return (
        <Card
            className={styles.LargeCard}
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                minHeight: "450px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                    backgroundColor: "#67809F",
                    color: "#fff",
                    height: 60,
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}
                    >
                        <i className={"k-icon k-i-note"} />
                        <strong>Notes</strong>
                    </span>
                    <EventNoteCreate triggerReload={triggerReload} />
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {eventContext.event.id === eventId ? (
                    <DataGrid
                        showColumnsMultiSelect={false}
                        uri={`/Customer/CalendarEvent/Note/GetAll?calendarEventId=${eventId}&`}
                        customManualReload={reloadData}
                        style={{
                            minHeight: "380px",
                        }}
                    >
                        <GridColumn field="name" title="Note:" />
                        <GridColumn
                            width={200}
                            field="modified"
                            title="Date Modified:"
                            cell={(props) => {
                                return (
                                    <td>
                                        {formatDate(props.dataItem.modified)}
                                    </td>
                                );
                            }}
                        />
                        <GridColumn
                            width={130}
                            cell={(props) => (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    triggerReload={triggerReload}
                                    action={deleteEventNote}
                                    EditComponent={EventNoteEdit}
                                    requiredPermissionsAction={[
                                        PermissionsEnum.DeleteCalendarEvent,
                                    ]}
                                    requiredPermissionsEdit={[
                                        PermissionsEnum.EditCalendarEvent,
                                    ]}
                                />
                            )}
                        />
                    </DataGrid>
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
        </Card>
    );
};

export default EventNotesPortlet;
