export default class StringHelper {
    /**
     * @function isNullOrEmpty
     * @description Check if a string is null or empty
     * @param value {string} - The string to check
     * @return {boolean}
     */
    static isNullOrEmpty(value) {
        return value === null || value === undefined || value === "";
    }

    /**
     * @function addSpacesToCapitalLetters
     * @description Add spaces before capital letters in a string
     * @param value {string} - The string to modify
     * @return {string}
     */
    static addSpacesToCapitalLetters(value) {
        return value.replace(/([A-Z])/g, " $1").trim();
    }

    /**
     * @function truncate
     * @param value {String}
     * @param length {Number}
     * @param useDotNotation {Boolean}
     * @return {*|string}
     */
    static truncate(value, length, useDotNotation = true) {
        if (value !== null && value !== undefined) {
            if (value.length > length) {
                return `${value.substring(0, length)} ${
                    useDotNotation ? "..." : ""
                }`;
            }
        }

        return value;
    }

    /**
     * @function capitalize
     * @param value {String}
     * @return {string}
     */
    static capitalize(value) {
        return String(value).charAt(0).toUpperCase() + String(value).slice(1);
    }
}
