import React, { useEffect } from "react";
import { VendorDocumentModalWrapper } from "../../VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { useModal } from "@/hooks/useModal.js";
import { VendorDocumentType } from "@/resources/Enums/VendorDocumentType.js";

/**
 * @component GlobalVendorBillModalWrapper
 * @description Creates the ability to open the vendor bill modal from
 * anywhere instead of just in the vendor page.
 * @param {boolean} props.open Whether the modal is to be opened
 * @param {CallableFunction} props.onClose When the modal is closed
 * @returns {Component}
 */
const GlobalVendorBillModalWrapper = ({ open, onClose }) => {
    const documentModal = useModal();

    /**
     * When the open prop changes to true,
     * open the modal with default settings.
     */
    const onOpen = () => {
        documentModal.open(undefined, {
            documentNumber: "000000",
            type: VendorDocumentType.Enum.VendorBill,
        });
    }

    /**
     * Listen for a change in the open prop
     */
    useEffect(() => {
        if (open) onOpen();
    }, [open])

    /**
     * Listen for changes on the visiblity
     * of the modal. If it is not visible, call the
     * onClose method
     */
    useEffect(() => {
        if (documentModal.visible === false) onClose();
    }, [documentModal.visible])

    return (<VendorDocumentModalWrapper {...documentModal} />);
};

export default GlobalVendorBillModalWrapper;
