import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell.jsx";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum.js";
import EmployeeService from "../../../services/EmployeeService.js";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider.jsx";
import { ToastContext } from "../../../providers/ToastProvider.jsx";

const InActiveEmployeeDashboardPage = () => {
    const { triggerReload } = useContext(ReloadDataContext);
    const { showToast } = useContext(ToastContext);
    const initialSort = [
        {
            field: "FirstName",
            dir: "asc",
        },
    ];

    const reActivate = (id) => {
        const empService = new EmployeeService();
        empService.activate(id).then((res) => {
            if (res.success) {
                triggerReload();
                showToast("Employee reactivated successfully", "success");
            }
        });
    };

    return (
        <DataGrid
            uri={"/HumanResources/Employee/GetAll?"}
            initialSort={initialSort}
            initialFilter={{
                logic: "and",
                filters: [
                    {
                        field: "IsActive",
                        operator: "eq",
                        value: false,
                    },
                ],
            }}
            searchFields={[
                "firstName",
                "lastName",
                "email",
                "phoneOne.phoneNumber",
                "laborType.name",
                "payrollId",
            ]}
        >
            <GridColumn
                field="fullName"
                title="Name:"
                filter={"text"}
                width={300}
            />
            <GridColumn
                field="address.fullAddress"
                title="Address:"
                filter={"text"}
                width={"200px"}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            props.dataItem.address.fullAddress
                        )}`}
                        text={props.dataItem.address.fullAddress}
                        target="_blank"
                    />
                )}
            />
            <GridColumn
                field="email"
                title="Email:"
                filter={"text"}
                width={"150px"}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`mailto:${props.dataItem.email}`}
                        text={props.dataItem.email}
                    />
                )}
            />
            <GridColumn
                field="phoneOne.phoneNumber"
                title="Primary Phone:"
                filter={"text"}
                width={"150px"}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`tel:${props.dataItem?.phoneOne?.phoneNumber}`}
                        text={props.dataItem?.phoneOne?.phoneNumber}
                    />
                )}
            />
            <GridColumn
                field="laborType.name"
                title="Labor Type:"
                filter={"text"}
                width={"150px"}
            />
            <GridColumn
                field="payrollId"
                title="Payroll ID:"
                filter={"text"}
                width={"150px"}
            />
            <GridColumn
                width={125}
                filterable={false}
                sortable={false}
                cell={(props) => (
                    <ActionCell
                        additionalActions={[
                            {
                                text: "Reactivate",
                                onClick: () => reActivate(props.dataItem.id),
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText:
                                        "Are you sure you want to reactivate this employee?",
                                },
                                requiredPermissions: [
                                    PermissionsEnum.EditEmployee,
                                ],
                            },
                        ]}
                    />
                )}
            />
        </DataGrid>
    );
};

export default InActiveEmployeeDashboardPage;
