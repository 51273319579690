import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { deleteLaborType } from "../../../../../services/Deprecated/humanResources/laborTypeServices";
import { LaborTypeModal } from "./Components/LaborTypeModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const LaborTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <LaborTypeModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add LaborType"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateLaborType]}
            />
            <DataGrid
                uri={"/HumanResources/LaborType/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteLaborType(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.EditLaborType],
                                delete: [PermissionsEnum.DeleteLaborType],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default LaborTypeDashboardPage;
