import React, { useContext, useEffect, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import ItemCategoryModal from "./ItemCategoryModal";
import { ItemRelationshipsContext } from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { useModal } from "../../../../hooks/useModal";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import ItemVendorsGrid from "./ItemVendorsGrid";
import { FileManager } from "../../../../components/FileManager/FileManager";
import { AccountDropdownItemRenderer } from "../../../../components/DropDownRenders/AccountDropDownRender.jsx";
import { useAccounts } from "../../../../hooks/Deprecated/useAccounts.js";
import Alert from "@/components/AlertComponent";

const ItemInformationForm = ({ itemCount, item, formRenderProps, setInitialFormValues, showCategory, create }) => {
    const { relationships } = useContext(ItemRelationshipsContext);
    const [itemType, setItemType] = useState("");
    const [currentItemType, setCurrentItemType] = useState({});
    const categoryModal = useModal();
    const { expenseAccounts, incomeAccounts } = useAccounts();
    const [key, setKey] = useState(1);
    const [defaultExpenseAccount, setDefaultExpenseAccount] = useState(false);
    const [defaultIncomeAccount, setDefaultIncomeAccount] = useState(false);

    useEffect(() => {
        incomeAccounts.map((a) => {
            if (a?.name?.toLowerCase() === 'revenue') {
                setDefaultIncomeAccount(a)

                if (create === true)
                    formRenderProps.onChange('incomeAccount', { value: a });
            }
        })
    }, [incomeAccounts]);

    useEffect(() => {
        expenseAccounts.map((a) => {
            if (a?.name?.toLowerCase() === 'expenses') {
                setDefaultExpenseAccount(a)

                if (create === true)
                    formRenderProps.onChange('expenseAccount', { value: a });
            }
        })
    }, [expenseAccounts]);

    useEffect(() => {
        if (item) {
            setItemType(item.itemType.name);
            setCurrentItemType(item.itemType);
        }
    }, [item]);

    const fileRestrictions = {
        allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
    };

    const handleOrderingOumChange = (e) => {
        if (currentItemType) {
            const oum = e.value;
            if (oum?.name === "N/A" && defaultExpenseAccount) {
                formRenderProps.onChange('expenseAccount', { value: defaultExpenseAccount });
            }
        }
    }

    const handleRetailOumChange = (e) => {
        if (currentItemType) {
            const oum = e.value;
            if (oum?.name === "N/A" && currentItemType?.logic != 'each') {
                formRenderProps.onChange('isStockOrSupplyItem', { value: true });
            }

            if (oum?.name === "N/A" && defaultIncomeAccount) {
                formRenderProps.onChange('incomeAccount', { value: defaultIncomeAccount });
            }
        }
    }

    const handleItemTypeChange = (e) => {

        /**
         * If no value is passed, reset
         */
        if (!e.target.value) {
            setCurrentItemType({});
            setItemType("");
            formRenderProps.onChange('units', { value: 1 })
            return;
        }

        /**
         * Update item type and units based on it
         */
        const selectedItemType = e.target.value;
        const selectedItemTypeName = selectedItemType.name;
        setCurrentItemType(selectedItemType);
        setItemType(selectedItemTypeName);

        if (selectedItemType.quantity) {
            formRenderProps.onChange('units', { value: selectedItemType.quantity })
        }
    };

    console.log(defaultExpenseAccount, defaultIncomeAccount)

    return (
        <div
            id="item-information-form"
            style={{
                display: "flex",
                gap: 20,
                width: "100%",
                flexWrap: "wrap",
            }}
        >
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    flex: `1 1 ${item?.id ? "45" : "100"}%`,
                    minWidth: 600,
                }}
            >
                <div className={"section-container"} style={{ marginTop: 15 }}>
                    <div className={"section-header"}>
                        General Information
                    </div>
                    <div className={"section-content"}>
                        <Field
                            name={"name"}
                            component={FormInput}
                            label={"Item Name:"}
                        />
                        <Field
                            name={"manufacturerNumber"}
                            component={FormInput}
                            label={"MFR #:"}
                        />
                        <Field
                            name={"yield"}
                            component={FormNumericInput}
                            format={"p"}
                            label={"Yield:"}
                            defaultValue={1}
                        />
                        <Field
                            name={"retailPrice"}
                            component={FormNumericInput}
                            format={"c2"}
                            label={"Retail Price:"}
                        />
                    </div>
                </div>
                <div className={"section-container"}>
                    <div className={"section-header"}>
                        Item Options
                    </div>
                    <div className={"section-content JustifyLeftAlignCenter FlexColumn LargeGap"}>
                        <Field
                            name={"isActive"}
                            component={Checkbox}
                            label={"Is Active"}
                            rounded={"large"}
                            size={"medium"}
                        />
                        <Field
                            name={"isStockOrSupplyItem"}
                            component={Checkbox}
                            label={"Is Stock or Supply Item"}
                            rounded={"large"}
                            size={"medium"}
                        />
                        <Field
                            name={"isDescriptionEditAllowed"}
                            component={Checkbox}
                            label={"Is Description Edit Allowed"}
                            rounded={"large"}
                            size={"medium"}
                        />
                    </div>
                </div>
                {/*
                 vendor can only be set initially, updating vendor later
                 must be done through vendor items list (or support something
                 other than a simple dropdown for vendor)
                 */}
                <div className={"section-container"}>
                    <div className={"section-header"}>
                        Item Specifics
                    </div>
                    <div className={"section-content"}>
                        {itemCount === "multi" ? (
                            <Field
                                name={"vendor"}
                                component={FormDropDown}
                                validator={requiredValidator}
                                data={relationships.vendors}
                                dataItemKey="id"
                                textField="name"
                                label={"Vendor:"}
                            />
                        ) : null}
                        <Field
                            name={"itemType"}
                            component={FormDropDown}
                            validator={requiredValidator}
                            data={relationships.itemTypes}
                            dataItemKey="id"
                            textField="name"
                            label={"What Type of Item is This?"}
                            onChange={handleItemTypeChange}
                        />
                        {currentItemType.logic === "slab" && (
                            <>
                                <Field
                                    name={"defaultWidth"}
                                    component={FormNumericInput}
                                    label={`Default Width (Left – right in inches):`}
                                    min={0}
                                    format={"0"}
                                    validator={requiredValidator}
                                />
                                <Field
                                    name={"defaultLength"}
                                    component={FormNumericInput}
                                    min={0}
                                    format={"0"}
                                    label={`Default Length (Top – bottom in inches):`}
                                    validator={requiredValidator}
                                />
                            </>
                        )}
                        {currentItemType.logic === "sheet" && (
                            <>
                                <Field
                                    name={"defaultWidth"}
                                    component={FormNumericInput}
                                    label={`Default Width (Inches):`}
                                    min={0}
                                    format={"0"}
                                    validator={requiredValidator}
                                />
                                <Field
                                    name={"defaultLength"}
                                    component={FormNumericInput}
                                    min={0}
                                    format={"0"}
                                    label={`Default Length (Inches):`}
                                    validator={requiredValidator}
                                />
                            </>
                        )}
                        {formRenderProps.valueGetter('itemType') && (
                            <>
                                <span
                                    style={{
                                        display: currentItemType?.logic === 'each' ? 'none' : 'block'
                                    }}
                                >
                                    <Field
                                        name={"units"}
                                        component={FormNumericInput}
                                        label={"How many units are in this item?"}
                                        defaultValue={currentItemType?.quantity ? currentItemType.quantity : 1}
                                        min={1}
                                        disabled={currentItemType?.logic === 'each'}
                                    />
                                </span>
                                {formRenderProps.valueGetter('units') && formRenderProps.valueGetter('units') > 1 && (
                                    <div className={"section-container"} style={{ marginTop: 15 }}>
                                        <div className={"section-header small"}>
                                            Unit Specifics
                                        </div>
                                        <div className={"section-content"}>
                                            <Field
                                                name={"unitItem"}
                                                component={FormDropDown}
                                                validator={requiredValidator}
                                                data={relationships.items}
                                                dataItemKey="id"
                                                textField="name"
                                                label={"Unit Item:"}
                                                hint={"Please select the item that defines each unit"}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <Field
                            name={"orderingUom"}
                            component={FormDropDown}
                            validator={requiredValidator}
                            data={relationships.unitOfMeasures}
                            dataItemKey="id"
                            textField="name"
                            label={"Ordering UOM (How the vendor ships):"}
                            onChange={handleOrderingOumChange}
                        />
                        <Field
                            name={"retailUom"}
                            component={FormDropDown}
                            validator={requiredValidator}
                            data={relationships.unitOfMeasures}
                            dataItemKey="id"
                            textField="name"
                            label={"Retail UOM (How we sell this item):"}
                            onChange={handleRetailOumChange}
                        />
                        <span
                            style={{
                                display: formRenderProps.valueGetter('orderingUom') &&
                                    formRenderProps.valueGetter('orderingUom').name === 'N/A' ?
                                    'none' :
                                    'block'
                            }}
                        >
                            <Field
                                name={"expenseAccount"}
                                component={FormDropDown}
                                data={expenseAccounts}
                                dataItemKey="id"
                                textField="name"
                                validator={requiredValidator}
                                label={"Expense Account (CoA where cost of POs are logged):"}
                                itemRender={AccountDropdownItemRenderer}
                            />
                        </span>

                        <span
                            style={{
                                display: formRenderProps.valueGetter('retailUom') &&
                                    formRenderProps.valueGetter('retailUom').name === 'N/A' ?
                                    'none' :
                                    'block'
                            }}
                        >
                            <Field
                                name={"incomeAccount"}
                                component={FormDropDown}
                                data={incomeAccounts}
                                dataItemKey="id"
                                textField="name"
                                validator={requiredValidator}
                                label={"Income Account (CoA where amounts on invoices are logged):"}
                                itemRender={AccountDropdownItemRenderer}
                            />
                        </span>
                        <ItemCategoryModal
                            {...categoryModal}
                            item={item}
                            formRenderProps={formRenderProps}
                        />
                        <span
                            className={'category-selector-container'}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'between',
                                gap: 5,
                                width: '100%',
                                display: showCategory === false ? 'none' : 'block'
                            }}
                        >
                            <Field
                                name={"category.name"}
                                component={FormInput}
                                label={"Category (Where it is listed in Item Categories):"}
                                readOnly={true}
                                width='50%'
                            />
                            <ActionButton
                                onClick={() => categoryModal.open()}
                                type={"button"}
                                text={"Change"}
                            />
                        </span>
                    </div>
                </div>

                {
                    formRenderProps.valueGetter('name') &&
                    (
                        formRenderProps.valueGetter('retailUom') &&
                        formRenderProps.valueGetter('retailUom').name !== 'N/A' ||
                        formRenderProps.valueGetter('orderingUom') &&
                        formRenderProps.valueGetter('orderingUom').name !== 'N/A'
                    ) && (
                        <>
                            <div className={"section-container"}>
                                <div className={"section-header"}>
                                    Descriptions
                                </div>
                                <div className={"section-content"}>
                                    {formRenderProps.valueGetter('retailUom') && formRenderProps.valueGetter('retailUom').name !== 'N/A' && (
                                        <>
                                            <h4
                                                style={{
                                                    margin: "0",
                                                    marginTop: "8px",
                                                    marginBottom: "8px",
                                                    borderBottom: "1px solid #ccc",
                                                    paddingBottom: "5px",
                                                    width: "55%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Estimate/Sales Order Description:
                                            </h4>
                                            <Field
                                                name={"baseDescription"}
                                                component={FormTextArea}
                                                autoSize={true}
                                                rows={4}
                                                validator={requiredValidator}
                                                label={"Estimate/Sales Order Description:"}
                                                style={{ marginBottom: 15 }}
                                            />
                                        </>
                                    )}
                                    {formRenderProps.valueGetter('orderingUom') && formRenderProps.valueGetter('orderingUom').name !== 'N/A' && (
                                        <>
                                            <h4
                                                style={{
                                                    margin: "0",
                                                    marginTop: "8px",
                                                    marginBottom: "8px",
                                                    paddingBottom: "5px",
                                                    width: "55%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Purchase Order Description:
                                            </h4>
                                            <Field
                                                name={"purchaseOrderDescription"}
                                                component={FormTextArea}
                                                autoSize={true}
                                                rows={4}
                                                validator={requiredValidator}
                                                label={"Purchase Order Description:"}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )
                }
            </span >
            <span
                style={{
                    flex: "1 1 50%",
                    minWidth: 600,
                }}
            >
                {item?.id && (
                    <span>
                        <br />
                        <ItemVendorsGrid item={item} />
                        <br />
                        <FileManager
                            disabled={false}
                            allowedExtensions={fileRestrictions}
                            saveUri={"/General/File/UploadFile"}
                            removeUri={"/General/File/DeleteFile"}
                            readUri={"/General/File/GetDirectoryContents"}
                            location={`ItemFiles-${item.id}`}
                        />
                    </span>
                )}
            </span>
        </div >
    );
};

export default ItemInformationForm;
