import React, { useContext, useEffect, useState } from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import { useHistory } from "react-router-dom";
import { getUserByEmployeeId } from "../../../services/Deprecated/authentication/userService";
import { UserContext } from "../../../providers/Deprecated/Authentication/User/UserProvider";
import { EmployeeContext } from "../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteEmployee } from "../../../services/Deprecated/humanResources/employeeServices";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import EmployeeTimeClockEntries from "./Components/EmployeeTimeClockEntries";
import EmployeeUserDetails from "./Components/EmployeeUserDetails";
import EmployeeListBox from "./Components/EmployeeListBox";
import EmployeeTabs from "./Components/EmployeeTabs";
import EmployeeEditForm from "./Components/EmployeeEditForm";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { ActionButton } from "../../../components/Buttons/ActionButton.jsx";

const EmployeeDetailsPage = (props) => {
    const employeeContext = useContext(EmployeeContext);
    const userContext = useContext(UserContext);

    /** @type {Employee} */
    const employee = employeeContext.employee;

    const employeeId = props?.match?.params?.id;
    const searchParams = new URLSearchParams(props.location.search);
    const [employeeHasUser, setEmployeeHasUser] = useState(false);

    useEffect(() => {
        employeeContext.setEmployeeId(employeeId);
    }, [employeeId]);

    useEffect(() => {
        if (employee?.id) {
            getUserByEmployeeId(employee.id)
                .then((res) => {
                    if (res.data.id) {
                        setEmployeeHasUser(true);
                        userContext.setUserId(res.data.id);
                    } else {
                        setEmployeeHasUser(false);
                    }
                })
                .catch(() => {
                    //? employee has no user, this is fine for now
                    setEmployeeHasUser(false);
                });
        }
    }, [employee]);

    const history = useHistory();

    const handleDeleteSuccess = () => {
        employeeContext.setEmployeeId(null);
        // Redirect to the employee dashboard page after successful deletion
        history.push("/HumanResources/Employee");
    };

    const gotoEmployees = () => {
        history.push(`/HumanResources/Employee`);
    };

    return (
        <div>
            <header className="vendor-name-header">
                {employeeContext.employee.fullName}
            </header>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card
                        className="option-card-container"
                        style={{ border: "none" }}
                    >
                        <table>
                            <tbody>
                                <tr
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    <ActionButton
                                        requiredPermissions={[
                                            PermissionsEnum.ViewEmployee,
                                        ]}
                                        icon={"k-icon k-i-undo"}
                                        onClick={() => gotoEmployees()}
                                    >
                                        Go To Employees
                                    </ActionButton>
                                    <ActionButtonsCommandCell
                                        record={employeeContext.employee}
                                        dataId={employeeId}
                                        action={(id) =>
                                            deleteEmployee(
                                                id,
                                                handleDeleteSuccess
                                            )
                                        }
                                        EditComponent={EmployeeEditForm}
                                        requiredPermissionsAction={[
                                            PermissionsEnum.DeleteEmployee,
                                        ]}
                                        requiredPermissionsEdit={[
                                            PermissionsEnum.EditEmployee,
                                        ]}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <div className="tab-container">
                        <Card
                            className="card-container"
                            style={{ border: "none" }}
                        >
                            <EmployeeTabs />
                        </Card>
                    </div>
                </div>
            </div>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card className="card-container" style={{ border: "none" }}>
                        <HighlightedTabStrip
                            initialSelected={Number(
                                searchParams.get("tabPosition")
                            )}
                        >
                            <TabStripTab title="Skills">
                                <EmployeeListBox
                                    employeeData={employeeContext.employee}
                                />
                            </TabStripTab>
                            <TabStripTab title="Time Clock Entries">
                                <EmployeeTimeClockEntries />
                            </TabStripTab>
                            {employeeHasUser && (
                                <TabStripTab title="User Details">
                                    <EmployeeUserDetails />
                                </TabStripTab>
                            )}
                        </HighlightedTabStrip>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetailsPage;
