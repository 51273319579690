import React, { useContext, useEffect, useRef, useState } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import {
    bulkPriceChange,
    deleteItem,
    getAllItemTypes,
    updateInLineGridItem,
} from "../../../services/Deprecated/inventory/itemService";
import ItemRelationshipsProvider from "../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { YesNoBooleanCell } from "../../../components/Deprecated/CustomGridCells";
import { useModal } from "../../../hooks/useModal";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import {
    PriceChangeModal,
    PriceChangeTypes,
} from "../Components/PriceChangeModal";
import { FilterOperator } from "../../../resources/Enums/FilterOperator";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import DetailItemService from "../../../services/Deprecated/customer/DetailItemService";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { EditableDropDownCell } from "../../../components/GridColumnComponents/EditableDropDownCell";
import { EditableTextCell } from "../../../components/GridColumnComponents/EditableTextCell";
import { ToggleButton } from "../../../components/Buttons/ToggleButton/ToggleButton";
import ItemPackageService from "../../../services/Deprecated/inventory/ItemPackageService";
import NewItemPackageService from "../../../services/ItemPackageService.js";
import { EditableNumericCell } from "../../../components/GridColumnComponents/EditableNumericCell";
import itemEditForm from "./Components/ItemEditForm";
import { DetailItemModal } from "./DetailItems/Components/DetailItemModal";
import { ItemPackageModal } from "./Packages/Components/ItemPackageModal";
import NewDetailItemService from "../../../services/DetailItemService";
import ItemService from "../../../services/ItemService.js";
import PermissionsHelper from "../../../resources/PermissionsHelper.js";
import { AuthContext } from "../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { useLocalStorage } from "@/hooks/useLocalStorage.js";
import { useAccounts } from "@/hooks/Deprecated/useAccounts.js";
import { BaseCell } from "../../../components/GridColumnComponents/BaseCell.jsx";
import { AccountDropdownItemRenderer } from "@/components/DropDownRenders/AccountDropDownRender.jsx";
import { EditableCheckBoxCell } from "../../../components/GridColumnComponents/EditableCheckBoxCell.jsx";
import { getAllUnitOfMeasures } from "../../../services/Deprecated/general/unitOfMeasureService.js";

/**
 * @function ItemDashboardPage
 * @description A dashboard for displaying and managing items
 * @param [props.initialFilter] {object} - Initial filter to apply to the grid
 * @return {Element}
 * @constructor
 */
const ItemDashboardPage = (props) => {
    const { initialFilter } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const { user } = useContext(AuthContext);
    const detailItemModal = useModal();
    const priceChangeModal = useModal();
    const itemPackageModal = useModal();
    const [hideColumns, setHideColumns] = useState(false);
    const [hideInActiveItems, setHideInActiveItems] = useState(false);
    const [columnsMayHideAutomatically, setColumnsMayHideAutomatically] =
        useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const refreshGridKey = useRef(Math.random());
    const hasUpdatePermission = PermissionsHelper.hasPermission(
        user.role.permissions,
        [PermissionsEnum.EditItem]
    );

    const localStorage = useLocalStorage();
    const [checkingLocalStorage, setCheckingLocalStorage] = useState(true);
    const [hiddenFieldsState, setHiddenFieldsState] = useState([]);
    const { incomeAccounts, expenseAccounts, isLoading } = useAccounts();
    const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);

    const loadUnitsOfMeasure = async () => {
        const uom = await getAllUnitOfMeasures();
        if (uom.data) {
            setUnitsOfMeasure(uom.data);
        }
    }

    useEffect(() => {
        const handleColumnHide = () => {
            if (window.innerWidth < 1700) {
                setHideColumns(true);
                setColumnsMayHideAutomatically(true);
            } else {
                setHideColumns(false);
                setColumnsMayHideAutomatically(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", () => {
            handleColumnHide();
        });

        // Set initial
        handleColumnHide();

        loadUnitsOfMeasure();
    }, []);

    const checkLocalStorage = () => {
        const existingHiddenFieldsState = localStorage.get("itemDashboardHiddenFields");
        if (existingHiddenFieldsState && existingHiddenFieldsState?.value) {
            setHiddenFieldsState(existingHiddenFieldsState.value);
        }
        setCheckingLocalStorage(false);
    }

    useEffect(() => {
        getAllItemTypes().then((data) => setItemTypes(data.data));
        checkLocalStorage();
    }, []);

    const grabCategoryIdFromFilter = () => {
        if (!initialFilter) return undefined;

        const categoryId = initialFilter.filters.find(
            (f) => f.field === "categoryId"
        );

        return categoryId ? categoryId.value : undefined;
    };

    const onPriceChange = async (data) => {
        const gridSearchQuery = document.getElementById("inLineGridSearch");
        const gridSearchOperator = document.getElementById(
            "gridStateSearchToggle"
        );

        const enumerable =
            gridSearchOperator?.value === "true"
                ? FilterOperator.Enum.StartsWith
                : FilterOperator.Enum.Contains;

        await bulkPriceChange({
            categoryId: grabCategoryIdFromFilter(),
            query: gridSearchQuery?.value,
            operator: enumerable,
            priceChangeInformation: data,
        });
    };

    const onSaveChanges = async (data) => {
        for (const item of data) {
            if (item.isDetailItem) {
                await DetailItemService.updateInLineGrid(item);
            } else if (item?.itemType?.name === "Package") {
                await ItemPackageService.updateInLineGrid(item);
            } else {
                await updateInLineGridItem({
                    ...item,
                    itemTypeId: item.itemType.id,
                    expenseAccountId: item.expenseAccount?.id,
                    incomeAccountId: item.incomeAccount?.id,
                    retailUomId: item.retailUom?.id,
                    orderingUomId: item.orderingUom?.id
                });
            }
        }

        triggerReload();
    };

    const duplicateItem = async (itemId, type) => {
        switch (type) {
            case "Detail":
                new NewDetailItemService()
                    .duplicate(itemId)
                    .then(() => triggerReload());
                break;
            case "Package":
                new NewItemPackageService()
                    .duplicate(itemId)
                    .then(() => triggerReload());
                break;
            default:
                new ItemService().duplicate(itemId).then(() => triggerReload());
                break;
        }
    };

    const onDetailItemDelete = (id) => {
        DetailItemService.delete(id).then(() => triggerReload());
    };

    const onItemDelete = async (id) => {
        await deleteItem(id);
        triggerReload();
    };

    const onPackageDelete = (id) => {
        ItemPackageService.delete(id).then(() => triggerReload());
    };

    const fetchActions = (dataItem) => {
        switch (dataItem.itemType?.name) {
            case "Detail":
                return (
                    <ActionCell
                        additionalActions={[
                            {
                                text: "Duplicate",
                                themeColor: "primary",
                                onClick: () =>
                                    duplicateItem(dataItem.id, "Detail"),
                                requiredPermissions: [
                                    PermissionsEnum.CreateItem,
                                ],
                                icon: "k-icon k-i-copy",
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to duplicate ${dataItem.name}?`,
                                },
                            },
                            {
                                text: "Edit",
                                themeColor: "primary",
                                onClick: () =>
                                    detailItemModal.open(dataItem.id),
                                requiredPermissions: [PermissionsEnum.EditItem],
                            },
                            {
                                text: "Delete",
                                themeColor: "primary",
                                onClick: () => onDetailItemDelete(dataItem.id),
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to delete ${dataItem.name}?`,
                                },
                                requiredPermissions: [
                                    PermissionsEnum.DeleteItem,
                                ],
                            },
                        ]}
                    />
                );
            case "Package":
                return (
                    <ActionCell
                        additionalActions={[
                            {
                                text: "Duplicate",
                                themeColor: "primary",
                                onClick: () =>
                                    duplicateItem(dataItem.id, "Package"),
                                requiredPermissions: [
                                    PermissionsEnum.CreateItem,
                                ],
                                icon: "k-icon k-i-copy",
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to duplicate ${dataItem.name}?`,
                                },
                            },
                            {
                                text: "Edit",
                                themeColor: "primary",
                                onClick: () =>
                                    itemPackageModal.open(dataItem.id),
                                requiredPermissions: [PermissionsEnum.EditItem],
                            },
                            {
                                text: "Delete",
                                themeColor: "primary",
                                onClick: () => onPackageDelete(dataItem.id),
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to delete ${dataItem.name}?`,
                                },
                                requiredPermissions: [
                                    PermissionsEnum.DeleteItem,
                                ],
                            },
                        ]}
                    />
                );
            default:
                return (
                    <ActionButtonsCommandCell
                        record={dataItem}
                        dataId={dataItem.id}
                        action={onItemDelete}
                        EditComponent={itemEditForm}
                        viewPage={{
                            uri: `/Inventory/Item/${dataItem.id}`,
                            breadcrumbInfo: [
                                {
                                    id: dataItem.id,
                                    text: dataItem.name,
                                },
                            ],
                        }}
                        requiredPermissionsEdit={[PermissionsEnum.EditItem]}
                        requiredPermissionsAction={[PermissionsEnum.DeleteItem]}
                        additionalButtons={[
                            <ActionButton
                                key={Math.random()}
                                text={"Duplicate"}
                                theme={"primary"}
                                onClick={() =>
                                    duplicateItem(dataItem.id, "Item")
                                }
                                requiredPermissions={[
                                    PermissionsEnum.CreateItem,
                                ]}
                                icon={"k-icon k-i-copy"}
                                confirmationState={{
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to duplicate ${dataItem.name}?`,
                                }}
                            />,
                        ]}
                    />
                );
        }
    };

    const generateDataGridFilters = () => {
        const filters = [
            {
                field: "isActive",
                operator: "eq",
                value: !hideInActiveItems,
            },
        ];

        if (initialFilter) {
            filters.push(initialFilter);
        }

        return filters;
    };

    const onColumnVisibilityChange = (data) => {
        localStorage.add("itemDashboardHiddenFields", data);
    }

    if (checkingLocalStorage) return (<></>);

    return (
        <ItemRelationshipsProvider>
            <div className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
                <DetailItemModal {...detailItemModal} />
                <ItemPackageModal {...itemPackageModal} />
                <PriceChangeModal
                    {...priceChangeModal}
                    submitCallback={onPriceChange}
                    fields={[
                        {
                            id: 1,
                            name: "Retail Price",
                        },
                    ]}
                    types={[
                        PriceChangeTypes.Dollar,
                        PriceChangeTypes.Percent,
                        PriceChangeTypes.PercentGreaterThanMaxCost,
                    ]}
                />
                <ActionButton
                    text={"Apply a Price Change"}
                    theme={"primary"}
                    onClick={() => priceChangeModal.open()}
                    requiredPermissions={[PermissionsEnum.EditItem]}
                    icon={"k-icon k-i-dollar"}
                />
                <ToggleButton
                    onToggleOn={() => {
                        setHideInActiveItems(true);
                        refreshGridKey.current = Math.random();
                    }}
                    onToggleOff={() => {
                        setHideInActiveItems(false);
                        refreshGridKey.current = Math.random();
                    }}
                    toggled={hideInActiveItems}
                    textStyle={{
                        fontWeight: 400,
                        fontSize: "14px",
                    }}
                    label={"Show InActive Items"}
                />
                {columnsMayHideAutomatically && (
                    <ToggleButton
                        onToggleOn={() => setHideColumns(false)}
                        onToggleOff={() => setHideColumns(true)}
                        toggled={!hideColumns}
                        textStyle={{
                            fontWeight: 400,
                            fontSize: "14px",
                        }}
                        label={"Show All Columns"}
                    />
                )}
                <DataGrid
                    initialHiddenFields={hiddenFieldsState}
                    onColumnVisibilityChange={onColumnVisibilityChange}
                    key={refreshGridKey.current}
                    style={{
                        maxWidth: "99.99%",
                    }}
                    onSaveChanges={onSaveChanges}
                    useInlineEdit={true}
                    uri={"/Customer/Job/Item/GetAll?"}
                    initialFilter={{
                        logic: "and",
                        filters: generateDataGridFilters(),
                    }}
                    searchFields={[
                        "name",
                        "baseDescription",
                        "purchaseOrderDescription",
                    ]}
                    resizable={true}
                    initialSort={[
                        {
                            field: "name", // Sorting by name field
                            dir: "asc", // Ascending order
                        },
                    ]}
                >
                    <GridColumn
                        className={"k-table-td"}
                        field={"name"}
                        title={"Item:"}
                        width={300}
                        minResizableWidth={250}
                        locked={true}
                        cell={(props) => (
                            <EditableTextCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                disabled={!hasUpdatePermission}
                            />
                        )}
                    />
                    {!hideColumns && (
                        <GridColumn
                            className={"k-table-td"}
                            field="baseDescription"
                            title="SE/SO Description:"
                            width={300}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    disabled={!hasUpdatePermission || props.dataItem.retailUom.name == 'N/A'}
                                    placeholder="DOES NOT SHOW ON SE/SO"
                                />
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            className={"k-table-td"}
                            field="purchaseOrderDescription"
                            title="PO Description:"
                            width={300}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    disabled={
                                        !hasUpdatePermission ||
                                        props.dataItem.itemType?.name ===
                                        "Detail" ||
                                        props.dataItem.itemType?.name ===
                                        "Package" ||
                                        props.dataItem.orderingUom.name == 'N/A'
                                    }
                                    placeholder={props.dataItem.orderingUom.name == 'N/A' ? "DOES NOT SHOW ON PO" : ''}
                                />
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    disabled={
                                        !hasUpdatePermission ||
                                        props.dataItem.itemType?.name ===
                                        "Detail" ||
                                        props.dataItem.itemType?.name ===
                                        "Package"
                                    }
                                />
                            )}
                            field="manufacturerNumber"
                            title="MFR #:"
                            width={100}
                            minResizableWidth={50}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="itemType"
                            title="Item Type:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableDropDownCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    data={itemTypes}
                                    textField={"name"}
                                    disabled={
                                        !hasUpdatePermission ||
                                        props.dataItem.itemType?.name ===
                                        "Detail" ||
                                        props.dataItem.itemType?.name ===
                                        "Package"
                                    }
                                />
                            )}
                        />
                    )}
                    <GridColumn
                        filterable={false}
                        editor={"numeric"}
                        field="units"
                        title="Units:"
                        width={150}
                        minResizableWidth={50}
                        cell={(props) => (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                disabled={
                                    !hasUpdatePermission ||
                                    props.dataItem.itemType?.name === "Package" ||
                                    props.dataItem.itemType?.name === "Detail"
                                }
                            />
                        )}
                    />
                    <GridColumn
                        editor={"numeric"}
                        field="yield"
                        title="Yield:"
                        width={150}
                        minResizableWidth={50}
                        cell={(props) => (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                format={"p"}
                                disabled={
                                    !hasUpdatePermission ||
                                    props.dataItem.itemType?.name === "Package" ||
                                    props.dataItem.itemType?.name === "Detail"
                                }
                            />
                        )}
                    />
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="retailUom"
                            title="Retail UOM:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableDropDownCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    data={unitsOfMeasure}
                                    textField={"name"}
                                    disabled={
                                        !hasUpdatePermission ||
                                        props.dataItem.itemType?.name ===
                                        "Detail" ||
                                        props.dataItem.itemType?.name ===
                                        "Package"
                                    }
                                />
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="orderingUom"
                            title="Ordering UOM:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableDropDownCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    data={unitsOfMeasure}
                                    textField={"name"}
                                    disabled={
                                        !hasUpdatePermission ||
                                        props.dataItem.itemType?.name ===
                                        "Detail" ||
                                        props.dataItem.itemType?.name ===
                                        "Package"
                                    }
                                />
                            )}
                        />
                    )}
                    <GridColumn
                        editor={"numeric"}
                        field="retailPrice.amount"
                        title="Retail Price:"
                        width={150}
                        minResizableWidth={50}
                        cell={(props) => (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                format={"c2"}
                                disabled={
                                    !hasUpdatePermission ||
                                    props.dataItem.itemType?.name === "Package"
                                }
                            />
                        )}
                    />
                    <GridColumn
                        editable={false}
                        field="maxVendorCost.amount"
                        title="Max Vendor Cost:"
                        width={150}
                        minResizableWidth={50}
                        format={"{0:c}"}
                    />
                    <GridColumn
                        width={75}
                        editor={"boolean"}
                        field="isActive"
                        title="Active:"
                        resizable={false}
                        cells={{
                            data: YesNoBooleanCell,
                        }}
                    />
                    <GridColumn
                        width={180}
                        editable={false}
                        field="isStockOrSupplyItem"
                        title="Stock or Supply Item"
                        resizable={false}
                        cell={(props) => (
                            <>
                                {props.dataItem.itemType?.name === "Package" || props.dataItem.itemType?.name === "Detail" ? (
                                    <BaseCell {...props} />
                                ) : (
                                    <EditableCheckBoxCell
                                        {...props}
                                        editField={"isStockOrSupplyItem"}
                                    />
                                )}
                            </>
                        )}
                    />
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="expenseAccount"
                            title="Expense Account:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <>
                                    {props.dataItem.itemType?.name === "Package" || props.dataItem.itemType?.name === "Detail" ? (
                                        <BaseCell></BaseCell>
                                    ) : (
                                        <EditableDropDownCell
                                            {...props}
                                            editField={"IN_LINE_EDIT"}
                                            data={expenseAccounts}
                                            textField="name"
                                            disabled={!hasUpdatePermission}
                                            itemRender={AccountDropdownItemRenderer}
                                        />
                                    )}
                                </>
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="incomeAccount"
                            title="Income Account:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <>
                                    {props.dataItem.itemType?.name === "Package" || props.dataItem.itemType?.name === "Detail" ? (
                                        <BaseCell></BaseCell>
                                    ) : (
                                        <EditableDropDownCell
                                            {...props}
                                            editField={"IN_LINE_EDIT"}
                                            data={incomeAccounts}
                                            textField="name"
                                            disabled={!hasUpdatePermission}
                                            itemRender={AccountDropdownItemRenderer}
                                        />
                                    )}
                                </>
                            )}
                        />
                    )}
                    <GridColumn
                        width={275}
                        resizable={false}
                        filterable={false}
                        editable={false}
                        sortable={false}
                        cell={(props) => fetchActions(props.dataItem)}
                    />
                </DataGrid>
            </div>
        </ItemRelationshipsProvider>
    );
};

export default ItemDashboardPage;
