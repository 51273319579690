import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import JobCommunicationEdit from "./JobCommunicationEdit";
import JobCommunicationCreate from "./JobCommunicationCreate";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { ColumnMenu } from "../../../../components/Deprecated/ColumnMenu";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteJobCommunication } from "../../../../services/Deprecated/customer/jobServices";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const JobCommunications = () => {
    const jobContext = useContext(JobContext);

    return jobContext.job.id ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {jobContext.job.isMarkedDead || jobContext.job.isArchived ? (
                <ActionButton
                    disabled={true}
                    disabledToolTipMessage={
                        "Tip: Cannot create a communication for a job that is marked dead or archived."
                    }
                    style={{ marginBottom: "5px" }}
                    icon={"k-icon k-i-plus"}
                >
                    Create New Communication
                </ActionButton>
            ) : (
                <JobCommunicationCreate />
            )}
            <DataGrid
                uri={`/Customer/Job/Communication/GetAll?jobId=${jobContext.job.id}&`}
                style={{ height: "225px" }}
            >
                <GridColumn
                    field="note"
                    title="Notes:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                />
                <GridColumn field="employeeWhoCreated.name" title="Creator:" />
                <GridColumn
                    field="created"
                    title="Date Created:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.created}
                        />
                    )}
                />
                <GridColumn
                    field="lastModified"
                    title="Date Modified:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.lastModified}
                        />
                    )}
                />
                <GridColumn
                    width={130}
                    cell={(props) =>
                        jobContext.job.isMarkedDead ||
                        jobContext.job.isArchived ? (
                            <td
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <ActionButton
                                    disabled={true}
                                    disabledToolTipMessage={
                                        "Tip: Cannot edit or delete a job communication that is marked dead or archived."
                                    }
                                >
                                    Edit
                                </ActionButton>
                                <ActionButton
                                    disabled={true}
                                    disabledToolTipMessage={
                                        "Tip: Cannot edit or delete a job communication that is marked dead or archived."
                                    }
                                >
                                    Delete
                                </ActionButton>
                            </td>
                        ) : (
                            <ActionButtonsCommandCell
                                record={props.dataItem}
                                dataId={props.dataItem.id}
                                action={deleteJobCommunication}
                                EditComponent={JobCommunicationEdit}
                                requiredPermissionsEdit={[
                                    PermissionsEnum.EditJob,
                                ]}
                                requiredPermissionsAction={[
                                    PermissionsEnum.DeleteJob,
                                ]}
                            />
                        )
                    }
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default JobCommunications;
