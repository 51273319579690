import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";
import { getAllBillingTerms } from "../../../../services/Deprecated/inventory/billingTermService.js";
import { getAllUnitOfMeasures } from "../../../../services/Deprecated/general/unitOfMeasureService.js";
import {
    getAllItemTypes,
    getItem,
} from "../../../../services/Deprecated/inventory/itemService.js";
import { getAllVendors } from "../../../../services/Deprecated/inventory/vendorService.js";
import { getAllItems } from "../../../../services/Deprecated/inventory/itemService.js";

export const ItemRelationshipsContext = createContext(null);

const ItemRelationshipsProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const [relationships, setRelationships] = useState({});
    const [item, setItem] = useState(undefined);
    const [itemId, setItemId] = useState();
    const [manualReload, setManualReload] = useState({});

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const billingTermsServiceResponse = await getAllBillingTerms();
                const unitOfMeasuresServiceResponse =
                    await getAllUnitOfMeasures();
                const itemTypesServiceResponse = await getAllItemTypes();
                const vendorServiceResponse = await getAllVendors();
                const itemsServiceResponse = await getAllItems();

                setRelationships({
                    billingTerms: billingTermsServiceResponse.data ?? [],
                    unitOfMeasures: unitOfMeasuresServiceResponse.data ?? [],
                    itemTypes: itemTypesServiceResponse.data ?? [],
                    vendors: vendorServiceResponse.data ?? [],
                    items: itemsServiceResponse.data ?? []
                });
            } catch (error) {
                console.log("Error fetching relationships data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!itemId) {
            setItem({});
            return;
        }

        const fetchItemData = async (itemId) => {
            try {
                const res = await getItem(itemId);
                setItem(res.data);
            } catch (error) {
                console.error("Error fetching item details:", error);
            }
        };

        fetchItemData(itemId).then(() => {
            console.log("Fetched Item Details");
        });
    }, [itemId, reloadDataContext.reloadData, manualReload]);

    const value = {
        relationships,
        setRelationships,
        item,
        setItemId,
        setManualReload,
    };

    return (
        <ItemRelationshipsContext.Provider value={value}>
            {children}
        </ItemRelationshipsContext.Provider>
    );
};

export default ItemRelationshipsProvider;
