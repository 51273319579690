import * as React from "react";
import { useEffect, useState } from "react";
import { BaseCell } from "./BaseCell";
import { Checkbox } from "@progress/kendo-react-inputs";

/**
 * @function EditableCheckBoxCell
 * @description A cell that allows for editing via a check box.
 * @param props.field {string} - The field to edit
 * @param props.dataItem {object} - The data item to edit
 * @param props.editField {string} - The field to watch for editing
 * @param props.onChange {function} - The function to call when the value changes
 * @param props.size {String}
 * @return {Element}
 * @constructor
 */
export const EditableCheckBoxCell = (props) => {
    const {
        field = "",
        dataItem,
        editField,
        onChange,
        disabled,
        placeholder,
        size,
        ...others
    } = props;
    const [value, setValue] = useState(dataItem[field]);

    useEffect(() => {
        const fields = field.split(".");
        let data;

        fields.forEach((f) => {
            data = data ? data[f] : dataItem[f];
        });

        setValue(data);
    }, [dataItem[field]]);

    const handleChange = (e) => {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
            setValue(e.target.value);
        }
    };

    return (
        <BaseCell {...others}>
            {typeof dataItem[editField] !== 'undfined' ? (
                <Checkbox
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    size={size}
                />
            ) : (
                value
            )}
        </BaseCell>
    );
};
