import React, { useContext, useEffect, useState } from "react";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { DataGrid } from "../../../../../../components/Grids/DataGrid.jsx";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { BaseCell } from "../../../../../../components/GridColumnComponents/BaseCell.jsx";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton.jsx";
import { EditableNumericCell } from "../../../../../../components/GridColumnComponents/EditableNumericCell.jsx";
import { CenterLoader } from "../../../../../../components/Deprecated/CenterLoader.jsx";
import { formatUSDCurrency } from "../../../../../../resources/Deprecated/currencyHelper.js";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { FormDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import { useModal } from "../../../../../../hooks/useModal.js";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell.jsx";
import { VendorDocumentModalHelper } from "../Helpers/VendorDocumentModalHelper.js";
import { EditableTextCell } from "../../../../../../components/GridColumnComponents/EditableTextCell.jsx";
import { EditableDropDownCell } from "../../../../../../components/GridColumnComponents/EditableDropDownCell.jsx";
import BaseService from "../../../../../../services/BaseService.js";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum.js";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import RowRenderHighlighter from "../../../../../../components/RowRenders/RowRenderHighlighter.jsx";
import { RemoteDropDown } from "../../../../../../components/FormInputs/RemoteDropDown.jsx";
import { ReceivalModal } from "./ReceivalModal/ReceivalModal.jsx";
import dayjs from "dayjs";
import LineItemHelper from "../../../../../../resources/LineItemHelper.js";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { getAllBillingAdjustments } from "../../../../../../services/Deprecated/inventory/billingAdjustmentService.js";

/**
 * @function VendorDocumentLineItems
 * @description Component to display line items for a vendor document
 * @return {Element}
 * @constructor
 */
export const VendorDocumentLineItems = () => {
    const {
        formData,
        lineItems,
        reloadItemGridKey,
        reloadItemGrid,
        canEdit,
        canReceive,
        submitLoaderVisible,
        onModalClose,
        closeAfterSubmit,
        hasPendingChanges,
        reloadVendorDocumentFromApi,
    } = useContext(VendorDocumentModalContext);
    const [runningTotal, setRunningTotal] = useState(0);
    const lineItemModal = useModal();
    const receivalModal = useModal();
    const [availableJobs, setAvailableJobs] = useState([]);
    const [isVendorBill, setIsVendorBill] = useState(false);

    useEffect(() => {
        if (!formData.vendor) return;

        if (
            formData.type &&
            formData.type === VendorDocumentType.Enum.VendorBill
        ) {
            setIsVendorBill(true);
        }

        const service = new BaseService("Inventory/Vendor/Job");
        service.getAll(`vendorId=${formData.vendor.id}`).then((res) => {
            setAvailableJobs(res.data);
        });
    }, [formData?.vendor?.id, formData?.id]);

    useEffect(() => {
        calculateAndSetRunningTotal();
    }, [lineItems.current]);

    const removeItemFromDocument = (item) => {
        lineItems.current = lineItems.current.filter((i) => i !== item);
        reloadItemGrid();
    };

    const canEditDescription = (li) => {
        if (!canEdit) return false;
        if (li.type !== NewItemTypeEnum.Enum.Item) return true;
        return li.item.isDescriptionEditAllowed;
    };

    const handlePriceAndQuantityChange = (e) => {
        lineItems.current = lineItems.current.map((item) => {
            if (item.id === e.dataItem.id) {
                item.total.amount = LineItemHelper.getLineItemTotal(item);
            }
            return item;
        });
    };

    const calculateAndSetRunningTotal = () => {
        const total = lineItems.current.reduce(
            (acc, item) => item.total?.amount ? acc + item.total.amount : 0,
            0
        );
        setRunningTotal(total);
    };

    const addNote = () => {
        lineItems.current = [
            ...lineItems.current,
            VendorDocumentModalHelper.formatLineItemForGrid({
                id: Math.random(),
                item: {
                    name: "---NOTE---",
                    poDescription: "Enter note details here...",
                    retailPrice: { amount: 0, currency: "USD" },
                    orderingUom: { name: "Each" },
                },
                type: NewItemTypeEnum.Enum.Note,
                job: formData.job
            }),
        ];
        reloadItemGrid();
    };

    const addItem = () => {
        if (isVendorBill) {
            lineItemModal.open(undefined, {
                type: NewItemTypeEnum.Enum.VendorBillLineItem,
                title: "Add PO"
            });
        } else {
            lineItemModal.open(undefined, {
                type: NewItemTypeEnum.Enum.Item,
            });
        }
    };

    return (
        <DataGrid
            key={reloadItemGridKey}
            style={{
                maxWidth: "99.99%",
                minHeight: 125,
            }}
            useInlineEdit={true}
            data={lineItems.current}
            resizable={true}
            initialSort={[]}
            disabledPagination={true}
            showSaveChangesButtonGroup={false} // We need our own custom solution
            rowRender={(row, props) =>
                RowRenderHighlighter(row, {
                    isDanger: !props.dataItem.item.isActive,
                })
            }
        >
            <GridToolbar>
                <span>
                    {submitLoaderVisible && (
                        <span className={"JustifyCenterAndAlignCenter"}>
                            <CenterLoader />
                        </span>
                    )}
                    {canEdit && (
                        <>
                            <ActionButton
                                requiredPermissions={[
                                    PermissionsEnum.EditVendorDocument,
                                ]}
                                theme={"primary"}
                                text={"Save"}
                                disabled={
                                    !formData.vendor || submitLoaderVisible
                                }
                                disabledToolTipMessage={
                                    !formData.vendor
                                        ? "Please select a vendor"
                                        : "Loading..."
                                }
                                onClick={() =>
                                    (hasPendingChanges.current = false)
                                }
                                className={"export-hide"}
                            />
                            <ActionButton
                                requiredPermissions={[
                                    PermissionsEnum.EditVendorDocument,
                                ]}
                                theme={"primary"}
                                text={"Save & Close"}
                                onClick={() =>
                                    (closeAfterSubmit.current = true)
                                }
                                disabled={
                                    !formData.vendor || submitLoaderVisible
                                }
                                disabledToolTipMessage={
                                    !formData.vendor
                                        ? "Please select a vendor"
                                        : "Loading..."
                                }
                                className={"export-hide"}
                            />
                        </>
                    )}
                    <ActionButton
                        requiredPermissions={[]}
                        theme={"primary"}
                        text={"Close"}
                        type={"button"}
                        disabled={submitLoaderVisible}
                        onClick={onModalClose}
                        className={"export-hide"}
                    />
                    <strong
                        className={"JustifyLeftAlignCenter"}
                        style={{ marginLeft: 10 }}
                    >
                        Running Total: {formatUSDCurrency(runningTotal)}
                    </strong>
                </span>
                <ReceivalModal {...receivalModal} />
                {canEdit && (
                    <>
                        <AddLineItemModal {...lineItemModal} />
                        {/* Spacer, yes, I hate this */}
                        <span
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                        <span className={"export-hide"}>
                            <ActionButton
                                requiredPermissions={[]}
                                theme={"primary"}
                                text={"Add Adjustment"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={() =>
                                    lineItemModal.open(undefined, {
                                        type: NewItemTypeEnum.Enum.Tax,
                                        title: "Add Adjustment"
                                    })
                                }
                            />
                            <ActionButton
                                requiredPermissions={[]}
                                theme={"primary"}
                                text={"Add Note"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={addNote}
                            />
                            <ActionButton
                                requiredPermissions={[]}
                                theme={"success"}
                                text={formData.type === VendorDocumentType.Enum.VendorBill ? "Add PO" : "Add Item"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                disabled={!formData.vendor}
                                disabledToolTipMessage={
                                    "Please select a vendor"
                                }
                                onClick={addItem}
                            />
                        </span>
                    </>
                )}
            </GridToolbar>
            <GridColumn
                field={"item.name"}
                title={"Item:"}
                width={300}
                minResizableWidth={150}
                editable={false}
                cell={props => (
                    <BaseCell>
                        {props.dataItem.item.name}
                        {props.dataItem.item.unitItem?.name && (
                            <>
                                <br />
                                (Unit Item: {props.dataItem.item.unitItem.name})
                            </>
                        )}
                    </BaseCell>
                )}
            />
            <GridColumn
                className={"k-table-td"}
                field="description"
                title="Description:"
                width={"auto"}
                minResizableWidth={100}
                cell={(props) => (
                    <EditableTextCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        disabled={!canEditDescription(props.dataItem)}
                    />
                )}
            />
            {formData.type !== VendorDocumentType.Enum.VendorBill && (
                <GridColumn
                    editor={"numeric"}
                    field="quantity"
                    title="Qty:"
                    width={150}
                    minResizableWidth={50}
                    cell={(props) => (
                        <EditableNumericCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            min={1}
                            onCustomChange={handlePriceAndQuantityChange}
                            onBlur={calculateAndSetRunningTotal}
                            disabled={
                                !canEdit ||
                                props.dataItem.type !== NewItemTypeEnum.Enum.Item
                            }
                        />
                    )}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.VendorBill && (
                <GridColumn
                    field={"item.units"}
                    title={"Units:"}
                    width={150}
                    minResizableWidth={150}
                    editable={false}
                    cell={props => (
                        <BaseCell>{props.dataItem.quantity * props.dataItem.item.units}</BaseCell>
                    )}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.PurchaseOrder && formData.type !== VendorDocumentType.Enum.VendorBill && (
                <GridColumn
                    editor={"numeric"}
                    field="quantityReceived"
                    title="Received:"
                    width={150}
                    minResizableWidth={50}
                    cell={(props) => (
                        <EditableNumericCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            min={0}
                            max={props.dataItem.quantity * props.dataItem.item.units}
                            disabled={
                                !canReceive ||
                                props.dataItem.type !==
                                NewItemTypeEnum.Enum.Item
                            }
                            onBlur={(e) => {
                                if (
                                    e.dataItem.quantityReceived === e.prevValue
                                ) {
                                    return;
                                }

                                receivalModal.open(undefined, {
                                    record: e.dataItem,
                                    prevValue: e.prevValue,
                                });
                            }}
                        />
                    )}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.PurchaseOrder && formData.type !== VendorDocumentType.Enum.VendorBill && (
                <GridColumn
                    className={"k-table-td"}
                    field="receiverInitials"
                    title="Receiver:"
                    width={125}
                    editable={false}
                    minResizableWidth={50}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.VendorBill && (
                // Job is not relevant for vendor bills
                // also causes some issues with notes/adjustments if job
                // isn't selected on document
                <GridColumn
                    className={"k-table-td"}
                    field="job"
                    title="Job:"
                    width={300}
                    minResizableWidth={100}
                    cell={(props) => (
                        <EditableDropDownCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            data={availableJobs}
                            textField={"name"}
                            valueField={"id"}
                            disabled={!canEdit}
                        />
                    )}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.ItemReceipt && (
                <GridColumn
                    editor={"numeric"}
                    field="price.amount"
                    title="Price:"
                    width={150}
                    minResizableWidth={50}
                    cell={(props) => {

                        if (formData.type === VendorDocumentType.Enum.VendorBill &&
                            props.dataItem.type === NewItemTypeEnum.Enum.Item) {
                            return (<BaseCell>${props.dataItem.total.amount.toFixed(2)}</BaseCell>)
                        }

                        return (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                format={"c2"}
                                min={formData.type !== VendorDocumentType.Enum.VendorBill ? 0 : undefined}
                                onCustomChange={handlePriceAndQuantityChange}
                                onBlur={calculateAndSetRunningTotal}
                                disabled={
                                    !canEdit ||
                                    props.dataItem.type === NewItemTypeEnum.Enum.Note
                                }
                            />
                        )
                    }}
                />
            )}
            <GridColumn
                editor={"numeric"}
                field="total.amount"
                title="Total:"
                width={100}
                minResizableWidth={50}
                editable={false}
                format={"{0:c2}"}
            />
            {canEdit && (
                <GridColumn
                    className={"export-hide"}
                    width={125}
                    resizable={false}
                    filterable={false}
                    editable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    text: "Remove",
                                    requiredPermissions: [],
                                    onClick: () =>
                                        removeItemFromDocument(props.dataItem),
                                    icon: "k-icon k-i-delete",
                                },
                            ]}
                        />
                    )}
                />
            )}
        </DataGrid>
    );
};

/**
 * @function AddLineItemModal
 * @description Modal to add a line item to the document
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const AddLineItemModal = (props) => {
    const { close, visible, record } = props;
    const [data, setData] = useState(undefined);
    const [multiItemData, setMultiItemData] = useState([]);
    const { reloadItemGrid, lineItems, formData } = useContext(
        VendorDocumentModalContext
    );
    const [selectedPO, setSelectedPO] = useState(undefined);
    const [items, setItems] = useState([]);
    const [billingAdjustments, setBillingAdjustments] = useState([]);

    const fetchBillingAdjustments = async () => {
        const billingAdjustmentsRes = await getAllBillingAdjustments();
        setBillingAdjustments(billingAdjustmentsRes)
    }


    useEffect(() => {
        setData(record);
        fetchBillingAdjustments();
    }, [visible]);

    const onClose = () => {
        setSelectedPO(undefined);
        setItems([]);
        setMultiItemData([]);
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        if (multiItemData.length) {
            lineItems.current = [...lineItems.current, ...multiItemData];
        } else if (data?.id) {
            lineItems.current = [...lineItems.current, data];
        } else {
            return;
        }

        reloadItemGrid();
        onClose();
    };

    useEffect(() => {
        if (!selectedPO) {
            setItems([]);
            return;
        }

        const service = new BaseService("Inventory/Vendor/Document");
        service.get(selectedPO.id).then((res) => {
            setItems(res.data.lineItems);
        });
    }, [selectedPO]);

    // Math.random() is used because the DataGrid requires an ID to correctly find the editing entity.
    // If this is not passed in, the DataGrid will not be able to find the entity and will change all entities
    // with an undefined ID to the same value.
    const generateFields = (lineItemType) => {
        switch (lineItemType) {
            case NewItemTypeEnum.Enum.Item:
                return (
                    <Field
                        url={
                            formData.job
                                ? `Customer/Job/Vendor/Item/GetAll?jobId=${formData.job.id}&`
                                : `/Inventory/Vendor/Item/GetAll?vendorId=${formData.vendor.id}&`
                        }
                        name={"item"}
                        useExactMatch={true}
                        component={RemoteDropDown}
                        label={"Item:"}
                        validator={requiredValidator}
                        style={{ width: "100%" }}
                        textField={"item.name"}
                        searchFields={["item.name"]}
                        disclaimer={(data) => {
                            if (data.length > 0) return;

                            if (formData.job) {
                                return `HINT: Selected Job (${formData?.job?.name}) needs no Items from Vendor (${formData?.vendor?.name})`;
                            } else {
                                return `HINT: Vendor (${formData?.vendor?.name}) has no Items to order.`;
                            }
                        }}
                        initialFilters={[
                            {
                                field: "vendorId",
                                operator: "eq",
                                value: formData.vendor.id,
                            },
                            {
                                field: "item.IsActive",
                                operator: "eq",
                                value: true,
                            },
                            {
                                field: "item.orderingUom.name",
                                operator: "neq",
                                value: "N/A",
                            },
                        ]}
                        onChange={(e) => {
                            if (!e.value) return;

                            const item = {
                                ...data,
                                item: e.value.item,
                                job: formData.job,
                                vendorItemId: e.value.id,
                                totalCost: e.value.totalCost,
                                id: Math.random(),
                            };

                            const lineItemData =
                                VendorDocumentModalHelper.formatLineItemForGrid(
                                    item
                                );

                            lineItemData.total.amount =
                                LineItemHelper.getLineItemTotal(lineItemData);

                            setData(lineItemData);
                        }}
                    />
                );
            case NewItemTypeEnum.Enum.Tax:
                return (
                    <Field
                        name={"cost"}
                        component={FormDropDown}
                        textField={"item.name"}
                        data={billingAdjustments.data.map(b => {
                            return {
                                ...b,
                                id: Math.random(),
                                item: {
                                    name: b.name,
                                    poDescription:
                                        "Enter details here...",
                                    retailPrice: { amount: 0, currency: "USD" },
                                    orderingUom: { name: "Each" },
                                },
                                price: { amount: 0, currency: "USD" },
                                type: NewItemTypeEnum.Enum.Adjustment,
                                billingAdjustmentId: b.id
                            }
                        })}
                        label={"Select Adjustment:"}
                        onChange={(e) => {
                            const cost = {
                                ...e.value,
                                job: formData.job,
                                id: Math.random(),
                            };
                            setData(
                                VendorDocumentModalHelper.formatLineItemForGrid(
                                    cost
                                )
                            );
                        }}
                    />
                );
            case NewItemTypeEnum.Enum.VendorBillLineItem:
                return (
                    <>
                        <strong>
                            Select a Purchase Order to add to the bill.
                        </strong>
                        <Field
                            url={`/Inventory/Vendor/Document/GetAll?type=${VendorDocumentType.Enum.PurchaseOrder}`}
                            name={"po"}
                            useExactMatch={true}
                            component={RemoteDropDown}
                            label={"Vendor Document:"}
                            validator={requiredValidator}
                            style={{ width: "100%" }}
                            textField={"documentNumber"}
                            searchFields={["documentNumber"]}
                            initialFilters={[
                                {
                                    field: "vendorId",
                                    operator: "eq",
                                    value: formData.vendor.id,
                                },
                                {
                                    field: "IsActive",
                                    operator: "eq",
                                    value: true,
                                },
                                {
                                    field: "Type",
                                    operator: "eq",
                                    value: VendorDocumentType.Enum.PurchaseOrder
                                }
                            ]}
                            dataMutator={(data) => {
                                return data
                                    .map((vd) => ({
                                        ...vd,
                                        originalDocumentNumber: vd.documentNumber,
                                        documentNumber: `(${dayjs(
                                            vd.created
                                        ).format("MM-DD-YYYY")}) #${vd.documentNumber
                                            } - $${vd.total.amount.toFixed(2)}`,
                                    }))
                            }}
                            onChange={(e) => {
                                const item = {
                                    ...e.value,
                                    item: {
                                        name: `PO #${e.value.originalDocumentNumber}`,
                                        poDescription: ''
                                    },
                                    job: formData.job,
                                    id: Math.random(),
                                    price: {
                                        amount: e.value.total.amount,
                                        currency: e.value.total.currency
                                    },
                                    type: 0,
                                    originalDocumentNotation: `PO #${e.value.originalDocumentNumber}`
                                };
                                setData(
                                    VendorDocumentModalHelper.formatLineItemForGrid(
                                        item
                                    )
                                );
                            }}
                        />
                    </>
                );
        }
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={record.title ? record.title : "Add Line Item"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {generateFields(record.type)}
                            <FormButtons
                                loaderVisible={false}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={true}
                                text={"Add"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

const VendorBillItemSelectionList = ({ items, onChange }) => {
    /** @type {import('react').CSSProperties} */
    const gridElementStyle = {
        border: "1px solid grey",
        padding: "10px",
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
                border: "1px solid grey",
            }}
        >
            {items.map((item) => {
                return (
                    <div key={item.id} style={{ display: "flex" }}>
                        <div style={gridElementStyle}>
                            <input
                                type={"checkbox"}
                                onChange={(e) => {
                                    const isAddToList = e.target.checked;
                                    onChange(item, isAddToList);
                                }}
                            ></input>
                        </div>
                        <div style={{ ...gridElementStyle, flexGrow: 2 }}>
                            {" "}
                            {item.item.name}
                        </div>
                        <div style={{ ...gridElementStyle, minWidth: 75 }}>
                            Qty: {item.quantity}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
