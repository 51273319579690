import React from "react";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { TabStripTab } from "@progress/kendo-react-layout";
import RoleSelection from "./Components/RoleSelection.jsx";
import RoleProvider from "./Providers/RoleProvider.jsx";
import PermissionSelection from "./Components/PermissionSelection/PermissionSelection.jsx";

const RoleDashboardPage = () => {
    return (
        <RoleProvider>
            <HighlightedTabStrip>
                <TabStripTab title="Role">
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            gap: 10,
                        }}
                    >
                        <RoleSelection />
                        <PermissionSelection />
                    </span>
                </TabStripTab>
            </HighlightedTabStrip>
        </RoleProvider>
    );
};

export default RoleDashboardPage;
