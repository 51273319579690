import { useEffect, useState } from "react";
import { getBillingTerm } from "@/services/Deprecated/inventory/billingTermService";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * Takes a billing term identifier and the startDate to
 * check against and returns the due date based on the terms.
 * @param {uuid} billingTermId The identifier of the billing term
 * @param {utc} startDate The date to check against 
 * @returns {object}
 */
export const useDueDate = ({ billingTermId, startDate }) => {
    const [loading, setLoading] = useState(false);
    const [billingTerm, setBillingTerm] = useState();
    const [dueDate, setDueDate] = useState();
    const [dueDateFormatted, setDueDateFormatted] = useState();

    /**
     * Get initial data for billing term
     * and determine due date.
     */
    const initial = async () => {
        setLoading(true);
        const res = await getBillingTerm(billingTermId);
        if (res.data) {
            setBillingTerm(res.data);
            const date = dayjs(startDate);
            setDueDate(date.add(res.data.dueDays, 'day'));
            setLoading(false);
        }
    }

    useEffect(() => {
        if (dueDate) {
            setDueDateFormatted(dayjs(dueDate).format("MM/DD/YYYY"));
        }
    }, [dueDate])

    useEffect(() => {
        if (billingTermId) initial();
    }, [billingTermId]);

    useEffect(() => {
        if (billingTermId) {
            if (startDate) initial();
        }
    }, [startDate]);

    return {
        loading,
        dueDate,
        dueDateFormatted,
        billingTerm
    };
};
