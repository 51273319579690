import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import * as React from "react";
import { useContext } from "react";
import VendorBillPaymentService from "../../../../services/Deprecated/inventory/vendorBillPaymentService";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import { VendorDocumentContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import { VendorBillPaymentContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorBillPaymentProvider";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const BillPaymentDetailsGrid = (props) => {
    const { dataItem } = props;
    const { setModalOperation, setSelectedDocumentId } = useContext(
        VendorDocumentContext
    );
    const billPaymentContext = useContext(VendorBillPaymentContext);

    const handleEditClick = (props) => {
        const { dataItem } = props;

        setModalOperation(undefined); //? Ensures modal for VD cannot open
        setSelectedDocumentId(dataItem.vendorDocumentId);
        billPaymentContext.setModalOperation("Update");
        billPaymentContext.setSelectedPayment(dataItem);
    };

    return (
        <Grid data={dataItem.payments}>
            <GridNoRecords> </GridNoRecords>
            <GridColumn
                field="type"
                title="Type:"
                locked={true}
                width={150}
                className={"text-overflow"}
            />
            <GridColumn
                field="details"
                title="Details:"
                width={365}
                className={"text-overflow"}
            />
            <GridColumn
                field="total.amount"
                filter={"numeric"}
                format={"{0:c}"}
                title="Amount:"
                width={150}
            />
            <GridColumn
                field="date"
                title="Date:"
                locked={true}
                width={150}
                cell={(props) => {
                    const dateObj = {
                        data: props.dataItem.date,
                    };
                    return CustomDateCell(dateObj);
                }}
            />
            <GridColumn
                width={130}
                cell={(props) => (
                    <ActionButtonsCommandCell
                        record={props.dataItem}
                        dataId={props.dataItem.id}
                        action={
                            VendorBillPaymentService.deleteVendorBillPayment
                        }
                        requiredPermissionsAction={[
                            PermissionsEnum.DeleteVendorPayments,
                        ]}
                        additionalButtons={[
                            <ActionButton
                                key={0}
                                onClick={() => handleEditClick(props)}
                                requiredPermissions={[
                                    PermissionsEnum.EditVendorPayments,
                                ]}
                            >
                                Edit
                            </ActionButton>,
                        ]}
                    />
                )}
            />
        </Grid>
    );
};

export default BillPaymentDetailsGrid;
