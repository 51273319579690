import React, { useContext, useEffect, useState } from "react";
import styles from "../Styles/VendorDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import StringHelper from "../../../../../../resources/StringHelper.js";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { FormDateInput } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredDateValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import dayjs from "dayjs";

/**
 * @function ExpectedDeliveryDate
 * @description Displays information for a Document about its expected delivery date
 * @return {React.JSX.Element}
 * @constructor
 */
export const ExpectedDeliveryDate = () => {
    const { formData } = useContext(VendorDocumentModalContext);
    const deliveryModal = useModal();

    return (
        formData.address && (
            <span
                className={"JustifyLeftAlignLeft"}
                style={{
                    fontSize: 16,
                    color: "#666666",
                }}
            >
                <DeliveryModal {...deliveryModal} />
                <span>
                    {formData.type === VendorDocumentType.Enum.VendorBill ? (
                        <>Delivery Date:{" "}</>
                    ) : (
                        <>Delivering:{" "}</>
                    )}
                    <DisplayDate formData={formData} modal={deliveryModal} />
                </span>
            </span>
        )
    );
};

/**
 * @function DisplayDate
 * @param props.formData {object} - formData object
 * @param props.modal {object} - modal object
 * @return {Element}
 * @constructor
 */
const DisplayDate = (props) => {
    const { formData, modal } = props;
    const { canEdit } = useContext(VendorDocumentModalContext);
    return canEdit ? (
        <span
            className={`text-overflow ${styles.LinkDisplay}`}
            onClick={() => modal.open()}
        >
            <i className={"k-icon k-i-pencil"} style={{ marginBottom: 4 }} />{" "}
            {StringHelper.isNullOrEmpty(formData.expectedDeliveryDate)
                ? "No Delivery Date Provided"
                : formData.expectedDeliveryDate}
        </span>
    ) : (
        <span className={`text-overflow`}>
            {StringHelper.isNullOrEmpty(formData.expectedDeliveryDate)
                ? "No Delivery Date Provided"
                : formData.expectedDeliveryDate}
        </span>
    );
};

/**
 * @function DeliveryModal
 * @description Modal for editing a delivery date
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const DeliveryModal = (props) => {
    const { close, visible, onSuccessCallback } = props;
    const [data, setData] = useState(undefined);
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        setData({
            expectedDeliveryDate: dayjs(formData.expectedDeliveryDate).toDate(),
        });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        setFormData({
            ...formData,
            expectedDeliveryDate: dayjs(data.expectedDeliveryDate.value).format(
                "MM/DD/YYYY"
            ),
        });

        reloadDocumentInformation();
        onClose();

        if (onSuccessCallback instanceof Function) {
            onSuccessCallback({
                expectedDeliveryDate: dayjs(
                    data.expectedDeliveryDate.value
                ).format("MM/DD/YYYY"),
            });
        }
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Delivery Date"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        name={"expectedDeliveryDate"}
                        component={FormDateInput}
                        label={"Delivery Date"}
                        validator={requiredDateValidator}
                        hint={"MM/DD/YYYY"}
                        onChange={(value) => {
                            setData({
                                ...data,
                                expectedDeliveryDate: value,
                                isValid: !requiredDateValidator(value?.value),
                            });
                        }}
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    text={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
