import { BaseEnum } from "./BaseEnum";

export class ItemTypeOptions extends BaseEnum {
    static Enum = {
        Each: 'each',
        Case: 'case',
        Sheet: 'sheet',
        Slab: 'slab',
        LinearFoot: 'linear-foot'
    };
}

export const getItemTypeFormatted = (e = null) => {
    if (!e) return false;

    switch (e) {
        case ItemTypeOptions.Enum.Each:
            return 'Each'
        case ItemTypeOptions.Enum.Case:
            return 'Case'
        case ItemTypeOptions.Enum.Sheet:
            return 'Sheet'
        case ItemTypeOptions.Enum.Slab:
            return 'Slab'
        case ItemTypeOptions.Enum.LinearFoot:
            return 'Linear Foot'
        default:
            return null;
    }
}

export const itemTypeOptionsList = [
    { label: getItemTypeFormatted(ItemTypeOptions.Enum.Each), value: ItemTypeOptions.Enum.Each },
    { label: getItemTypeFormatted(ItemTypeOptions.Enum.Case), value: ItemTypeOptions.Enum.Case },
    { label: getItemTypeFormatted(ItemTypeOptions.Enum.Sheet), value: ItemTypeOptions.Enum.Sheet },
    { label: getItemTypeFormatted(ItemTypeOptions.Enum.Slab), value: ItemTypeOptions.Enum.Slab },
    //{ label: getItemTypeFormatted(ItemTypeOptions.Enum.LinearFoot), value: ItemTypeOptions.Enum.LinearFoot },
];
