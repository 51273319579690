import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { BreadcrumbContext } from "../../../../../../providers/BreadCrumbProvider.jsx";
import styles from "../Styles/VendorDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { useModal } from "../../../../../../hooks/useModal.js";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import JobService from "../../../../../../services/JobService.js";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { VendorDocumentType } from "@/resources/Enums/VendorDocumentType.js";
import BusinessInformationService from "../../../../../../services/Deprecated/customer/businessInformationService.js";

/**
 * @function DocumentDetailsPortlet
 * @description Displays information for a Document about the Vendor and Job
 * @param props.formRenderProps {object} - form render props
 * @return {React.JSX.Element}
 * @constructor
 */
export const DocumentDetailsPortlet = (props) => {
    const { formRenderProps, type } = props;
    const { formData, lineItems, reloadDocumentInformation } = useContext(VendorDocumentModalContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const vendorAndJobModal = useModal();

    const handleVendorClick = (vendor) => {
        handleRouteChange({
            uri: `/Inventory/Vendor/${vendor.id}`,
            breadcrumbInfo: [{ id: vendor.id, text: vendor.name }],
        });
    };

    const handleJobClick = (job) => {
        handleRouteChange({
            uri: `/Customer/${job.customer.id}/Job/${job.id}`,
            breadcrumbInfo: [
                { id: job.id, text: job.name },
                { id: job.customer.id, text: job.customer.name },
            ],
        });
    };

    const openVendorJobModal = () => {
        vendorAndJobModal.open(undefined, {
            ...formData,
            formRenderProps: formRenderProps,
        })
    }

    useEffect(() => {
        if (!formData.vendor) {
            openVendorJobModal();
        }
    }, [formData?.vendor])

    return (
        formData && (
            <Card style={{ width: 350, height: 125 }}>
                <VendorAndJobModal {...vendorAndJobModal} type={formData.type} />
                <CardBody
                    className={"JustifyLeftAlignLeft FlexColumn SmallGap"}
                >
                    <DisplayTitle
                        formData={formData}
                        modal={vendorAndJobModal}
                        formRenderProps={formRenderProps}
                    />
                    <span className={"JustifyLeftAlignLeft SmallGap"}>
                        <span
                            className={
                                "JustifyRightAlignRight FlexColumn SmallGap"
                            }
                            style={{
                                width: 100,
                            }}
                        >
                            <strong>Vendor:</strong>
                            {!formData.job && lineItems.current.length > 0 && type !== VendorDocumentType.Enum.VendorBill && (
                                <strong>Type:</strong>
                            )}
                        </span>
                        <span
                            className={
                                "JustifyLeftAlignLeft FlexColumn SmallGap"
                            }
                        >
                            <DisplayName
                                text={formData.vendor?.name}
                                onClick={() =>
                                    handleVendorClick(formData.vendor)
                                }
                            />
                            {formData.type !== VendorDocumentType.Enum.VendorBill && (
                                <>
                                    {!formData.job && lineItems.current.length > 0 ? (
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                gap: 2,
                                                flexDirection: "column",
                                            }}
                                        >
                                            <span>Stock Order</span>
                                            <small style={{ fontStyle: "italic" }}>
                                                Items will be sent to stock
                                            </small>
                                        </span>
                                    ) : (
                                        <>
                                            {type !== VendorDocumentType.Enum.VendorBill && (
                                                <DisplayName
                                                    text={formData.job?.name}
                                                    onClick={() => handleJobClick(formData.job)}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </span>
                    </span>
                </CardBody>
            </Card>
        )
    );
};

/**
 * @function DisplayName
 * @param props.text {string} - text to display
 * @param props.onClick {function} - click event
 * @return {Element}
 * @constructor
 */
const DisplayName = (props) => {
    const { text, onClick } = props;

    return text ? (
        <a className={"Link text-overflow"} onClick={onClick}>
            {text}
        </a>
    ) : (
        <span style={{ color: "red" }}>**Not Provided**</span>
    );
};

/**
 * @function DisplayTitle
 * @description Displays the title for the Document Details
 * @param props.formData {object} - form data
 * @param props.modal {object} - modal object
 * @param props.formRenderProps {object} - form render props
 * @return {Element}
 * @constructor
 */
const DisplayTitle = (props) => {
    const { formData, modal, formRenderProps } = props;
    const { canEdit, lineItems } = useContext(VendorDocumentModalContext);

    return canEdit && lineItems.current.length === 0 ? (
        <span
            onClick={() =>
                modal.open(undefined, {
                    ...formData,
                    formRenderProps: formRenderProps,
                })
            }
        >
            <CardTitle className={styles.LinkDisplay}>
                <i
                    className={"k-icon k-i-pencil"}
                    style={{ marginBottom: 4 }}
                />{" "}
                Document Details:
            </CardTitle>
        </span>
    ) : (
        <CardTitle
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                gap: 5,
            }}
        >
            <Tooltip position={"top"}>
                <i
                    className={"k-icon k-i-question"}
                    title={
                        "Cannot edit details when Items present or Document in non-editable state."
                    }
                />
            </Tooltip>
            Document Details:
        </CardTitle>
    );
};

/**
 * @function VendorAndJobModal
 * @description Modal for editing a Vendor and Job
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const VendorAndJobModal = (props) => {
    const { close, visible, record, type } = props;
    const [data, setData] = useState(undefined);
    const [reloadVendorKey, setReloadVendorKey] = useState(Math.random());
    const { reloadDocumentInformation, setFormData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        if (!visible) return;

        const newData = { vendor: record?.vendor };
        if (type !== VendorDocumentType.Enum.VendorBill) newData.job = record?.job;
        setData(newData);
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = async () => {

        /**
         * If it is a vendor bill, grab the default
         * business information, skip the job requiremen
         */
        if (type === VendorDocumentType.Enum.VendorBill) {
            const businessInfo = await BusinessInformationService.getDefaultBusinessInformation();
            if (!businessInfo) return;

            setFormData({
                ...record,
                ...data,
                businessInformation: businessInfo.data,
                address: data.vendor?.address
            });

            reloadDocumentInformation();
        } else {
            // Fetch Job so we can also get Business Information
            const jobSer = new JobService();

            if (!data.vendor || !data.job) return;

            jobSer.get(data.job.id).then((res) => {
                setFormData({
                    ...record,
                    ...data,
                    job: res.data,
                    businessInformation: res.data.businessInformation,
                    address: res.data.businessInformation.address,
                });
                reloadDocumentInformation();
            });
        }
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Edit Vendor & Job"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        key={reloadVendorKey}
                        url={
                            data.job
                                ? `Customer/Job/Vendor/GetAll?jobId=${data.job.id}&`
                                : "/Inventory/Vendor/GetAll?"
                        }
                        name={"vendor"}
                        component={FormRemoteDropDown}
                        label={"Vendor:"}
                        validator={requiredValidator}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                            setData({
                                ...data,
                                vendor: e.value,
                                isValid: true,
                            })
                        }
                    />
                    {type !== VendorDocumentType.Enum.VendorBill && (
                        <Field
                            url={"/Customer/Job/GetAll?"}
                            name={"job"}
                            component={FormRemoteDropDown}
                            label={"Job:"}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    job: e.value,
                                    isValid: data?.vendor !== undefined,
                                });
                                setReloadVendorKey(Math.random());
                            }}
                        />
                    )}
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    text={"Update"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
