import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import StringHelper from "../../../resources/StringHelper.js";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Field } from "@progress/kendo-react-form";
import styles from "../../../pages/Customer/JobDocuments/Components/JobDocumentModal/Styles/JobDocumentModal.module.scss";
import { useModal } from "@/hooks/useModal.js";
import { VendorDocumentModalContext } from "../../../pages/Inventory/VendorDocuments/Components/VendorDocumentModal/Providers/VendorDocumentModalProvider.jsx";
import ResponsiveDialog from "../../Deprecated/DialogWrapper.jsx";

import { requiredValidator } from "@/resources/Deprecated/formValidators";
import { FormButtons } from "@/components/Buttons/FormButtons";
import { FormInput } from "@/components/Deprecated/FormComponents";

import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * @function DocumentInformationDisplay
 * @description Displays information for a Document
 * @param props.formData {object} - the data for the document
 * @param props.setFormData {function} - the function to set the form data
 * @param props.type {string} - the type of document
 * @return {React.JSX.Element}
 * @constructor
 */
export const DocumentInformationDisplay = (props) => {
    const { formData, setFormData, type, canDateBeEdited, canDocumentNumberBeEdited, reloadDocumentInformation } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [date, setDate] = useState(dayjs(formData.date).utc());
    const documentNumberModal = useModal();

    const displayType = () => {
        if (type === "ItemReceipt") return "PO Item Receipt";
        return StringHelper.addSpacesToCapitalLetters(type);
    };

    const onDateChange = (e) => {
        const value = e.value;
        setDate(value);
        setIsEditing(false);
        setFormData({
            ...formData,
            date: dayjs(value).format("MM/DD/YYYY")
        })
        reloadDocumentInformation();
    };

    return (
        <div className={`JustifyLeftAlignLeft FlexColumn MediumGap`}>
            <DocumentNumberModal
                formData={formData}
                setFormData={setFormData}
                reloadDocumentInformation={reloadDocumentInformation}
                {...documentNumberModal}
            />
            <span
                className={"JustifyLeftAlignLeft SmallGap"}
                style={{ fontSize: 32 }}
            >
                <strong>{displayType()}: </strong>
                {canDocumentNumberBeEdited ? (
                    <span
                        className={`text-overflow ${styles.LinkDisplay}`}
                        onClick={() => {
                            documentNumberModal.open(undefined)
                        }}
                    >
                        (#{formData.documentNumber})
                    </span>
                ) : (
                    <span>(#{formData.documentNumber})</span>
                )}
            </span>
            {isEditing ? (
                <Field
                    name={"date"}
                    component={() => (
                        <div style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <span style={{ fontSize: 16, color: "#666666", marginRight: 10 }}>Date:</span>
                            <DatePicker value={new Date()} onChange={onDateChange} />
                        </div>
                    )}
                    value={date}
                    show={true}
                />
            ) : canDateBeEdited ? (
                <span style={{ fontSize: 16, color: "#666666" }}>
                    Date:{" "}
                    <span
                        className={`text-overflow ${styles.LinkDisplay}`}
                        onClick={() => {
                            if (canDateBeEdited) {
                                setIsEditing(true);
                            }
                        }}
                    >
                        <i
                            className={"k-icon k-i-pencil"}
                            style={{ marginBottom: 4 }}
                        />{" "}
                        {dayjs(date).format("MM/DD/YYYY")}
                    </span>
                </span>
            ) : (
                <span
                    style={{ fontSize: 16, color: "#666666" }}
                    onClick={() => {
                        if (canDateBeEdited) {
                            setIsEditing(true);
                        }
                    }}
                >
                    Date: {dayjs(date).format("MM/DD/YYYY")}
                </span>
            )}
        </div>
    );
};

export const DocumentNumberModal = (props) => {
    const { close, visible, reloadDocumentInformation, setFormData, formData } = props;
    const [data, setData] = useState(undefined);

    useEffect(() => {
        setData({ documentNumber: formData?.documentNumber });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = async () => {
        setFormData({
            ...formData,
            documentNumber: data.documentNumber
        });

        reloadDocumentInformation();
        onClose();
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Document Number"}
                onClose={onClose}
                size={"small"}
            >
                <div style={{ width: "100%" }}>
                    <Field
                        name={"documentNumber"}
                        component={FormInput}
                        label={"Document Number"}
                        onChange={(value) => {
                            setData({
                                ...data,
                                documentNumber: value.value,
                                isValid: !requiredValidator(value?.value),
                            });
                        }}
                    />
                </div>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    isCreate={false}
                    text={"Apply Changes"}
                    allowSubmit={data.isValid}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
