import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { deletePhoneType } from "../../../../../services/Deprecated/general/phoneTypeServices";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { useModal } from "../../../../../hooks/useModal";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { PhoneTypeModal } from "./Components/PhoneTypeModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const PhoneTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <PhoneTypeModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add Phone Type"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreatePhoneType]}
            />
            <DataGrid
                uri={"/General/PhoneType/GetAll?"}
                style={{ maxWidth: "400px" }}
            >
                <GridColumn
                    field="name"
                    title="Name:"
                    filter={"text"}
                    locked={true}
                    width={"250px"}
                />
                <GridColumn
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() => {
                                deletePhoneType(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                );
                            }}
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.EditPhoneType],
                                delete: [PermissionsEnum.DeletePhoneType],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default PhoneTypeDashboardPage;
