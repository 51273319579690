import * as React from "react";
import { useContext, useState } from "react";
import "../../../styles/CommonPositionalStyles.scss";
import { AuthContext } from "../../../providers/Deprecated/Authentication/User/AuthProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { useHREmployees } from "../../../hooks/Deprecated/useHREmployees";
import { useRemindersReport } from "../Hooks/useRemindersReport";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import RowRenderHighlighter from "../../../components/RowRenders/RowRenderHighlighter";
import {
    CustomDateCell,
    YesNoBooleanCell,
} from "../../../components/Deprecated/CustomGridCells";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { useModal } from "../../../hooks/useModal";
import { ReminderModal } from "../../Customer/Reminders/Components/ReminderModal";
import PermissionsHelper from "../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../hooks/useUserPermissions.js";

const RemindersReport = () => {
    const { user } = useContext(AuthContext);
    const [currentEmployee, setCurrentEmployee] = useState({
        ...user.activeEmployee,
        fullName: user.activeEmployee.name,
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const hasAdminPermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        [PermissionsEnum.ViewReminders]
    );
    const { employees } = useHREmployees(hasAdminPermission);
    const { reminders } = useRemindersReport(currentEmployee.id, selectedDate);
    const reminderModal = useModal();

    return (
        <div
            style={{
                maxWidth: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <span
                className={"JustifyLeftAlignCenter LargeGap"}
                style={{
                    maxWidth: "100%",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: 10,
                    }}
                >
                    <DropDownList
                        data={employees}
                        textField="fullName"
                        dataItemKey="id"
                        defaultValue={currentEmployee}
                        disabled={!hasAdminPermission}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => setCurrentEmployee(e.value)}
                    />
                    <DatePicker
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.value)}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: "auto",
                    }}
                >
                    <ReminderModal {...reminderModal} />
                    <ActionButton
                        text={"Create Reminder"}
                        onClick={() => reminderModal.open()}
                        requiredPermissions={[PermissionsEnum.CreateReminders]}
                    />
                </div>
            </span>
            <Grid
                data={reminders.data}
                rowRender={(row, props) =>
                    RowRenderHighlighter(row, {
                        isWarning: !props.dataItem.isOverDue,
                    })
                }
            >
                <GridColumn field="message" title="Message:" />
                <GridColumn
                    field="isCompleted"
                    title="Completed:"
                    cells={{
                        data: YesNoBooleanCell,
                    }}
                    width={100}
                />
                <GridColumn
                    field="isOverdue"
                    title="Overdue:"
                    cells={{
                        data: YesNoBooleanCell,
                    }}
                    width={100}
                />
                <GridColumn
                    field="dueDate"
                    title="Due Date:"
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.reminderDate}
                        />
                    )}
                    width={100}
                />
                <GridColumn field="lead.name" title="Lead:" width={300} />
            </Grid>
        </div>
    );
};

export default RemindersReport;
