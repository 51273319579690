import React from "react";

/**
 * @function BusinessInformationDisplay
 * @description Displays business information for a Document.
 * @param props.formData {object} - form data
 * @return {React.JSX.Element}
 * @constructor
 */
export const BusinessInformationDisplay = (props) => {
    const { formData } = props;
    const businessInformation = formData?.businessInformation;

    return (
        businessInformation && (
            <div className={"JustifyLeftAlignLeft MediumGap"}>
                <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
                    <img
                        src={businessInformation.file.uri}
                        alt={`${businessInformation.businessName ?? "Unassigned"
                            } Logo`}
                        style={{
                            maxWidth: 125,
                            maxHeight: 90,
                        }}
                    />
                    <strong className={"text-overflow"}>
                        {businessInformation.businessName}
                    </strong>
                    <span className={"text-overflow"}>
                        {businessInformation?.phone?.phoneNumber}
                    </span>
                    <span className={"text-overflow"}>
                        {businessInformation.website}
                    </span>
                    <span className={"text-overflow"}>
                        {businessInformation.email}
                    </span>
                    <span className={"text-overflow"}>
                        {businessInformation.address.fullAddress}
                    </span>
                </span>
            </div>
        )
    );
};
