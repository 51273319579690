import React, { useContext, useEffect, useRef } from "react";
import { ActionButtonContainer } from "../../../../../components/Buttons/ActionButtonContainer.jsx";
import { ActionButton } from "../../../../../components/Buttons/ActionButton.jsx";
import DefaultRoleModal from "./DefaultRoleModal.jsx";
import { RemoteDropDown } from "../../../../../components/FormInputs/RemoteDropDown.jsx";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum.js";
import { RoleModal } from "./RoleModal.jsx";
import { useModal } from "../../../../../hooks/useModal.js";
import RoleService from "../../../../../services/RoleService.js";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider.jsx";
import { ToastContext } from "../../../../../providers/ToastProvider.jsx";
import { RoleContext } from "../Providers/RoleProvider.jsx";

/**
 * @function RoleSelection
 * @return {Element}
 * @constructor
 */
const RoleSelection = () => {
    const { role, setRole } = useContext(RoleContext);
    const { showToast } = useContext(ToastContext);
    const { triggerReload, reloadData } = useContext(ReloadDataContext);
    const roleModal = useModal();
    const roleServiceRef = useRef(new RoleService());

    // Load in permissions from the currently selected Role
    useEffect(() => {
        if (!role) {
            setRole(null);
            return;
        }

        roleServiceRef.current.get(role.id).then((res) => {
            if (res.success) {
                setRole(res.data);
            }
        });
    }, [reloadData]);

    const onDuplicate = () => {
        roleServiceRef.current.duplicate(role.id).then((res) => {
            if (res.success) {
                triggerReload();
                showToast(
                    `Successfully Duplicated Role: ${role.name}`,
                    "success"
                );
                setRole(res.data);
            }
        });
    };

    const onDelete = () => {
        roleServiceRef.current.delete(role.id).then((res) => {
            if (res.success) {
                triggerReload();
                showToast(`Successfully Deleted Role: ${role.name}`, "success");
                setRole(null);
            }
        });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
            }}
        >
            <RoleModal {...roleModal} />
            <ActionButtonContainer>
                <ActionButton
                    text={"Create"}
                    icon={"k-icon k-i-plus"}
                    requiredPermissions={[PermissionsEnum.CreateRole]}
                    onClick={() => roleModal.open()}
                />
                <DefaultRoleModal />
                {role && (
                    <>
                        <ActionButton
                            text={"Edit"}
                            icon={"k-icon k-i-pencil"}
                            requiredPermissions={[PermissionsEnum.EditRole]}
                            onClick={() => roleModal.open(role.id)}
                        />
                        <ActionButton
                            text={"Duplicate"}
                            icon={"k-icon k-i-copy"}
                            requiredPermissions={[PermissionsEnum.CreateRole]}
                            onClick={onDuplicate}
                            confirmationState={{
                                needsConfirmation: true,
                                confirmationText: `Are you sure you want to duplicate ${role?.name}?`,
                            }}
                        />
                        <ActionButton
                            text={"Delete"}
                            icon={"k-icon k-i-trash"}
                            themeColor={"error"}
                            disabled={role.isDefault}
                            requiredPermissions={[PermissionsEnum.DeleteRole]}
                            onClick={onDelete}
                            disabledToolTipMessage={
                                "Cannot delete the Tenants default Role, please re-assign default and try again."
                            }
                            confirmationState={{
                                needsConfirmation: true,
                                confirmationText: `Are you sure you want to delete ${role?.name}?`,
                            }}
                        />
                    </>
                )}
            </ActionButtonContainer>
            <hr
                className={"Separator"}
                style={{
                    opacity: "50%",
                }}
            />
            <RemoteDropDown
                key={reloadData}
                url={"/Authentication/Role/GetAll?"}
                label={"Select Role"}
                value={role}
                onChange={(e) => {
                    const ddRole = e.value;
                    if (role?.id !== ddRole?.id) {
                        setRole(ddRole);
                    }
                }}
            />
        </div>
    );
};

export default RoleSelection;
