import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

const sizeStyles = {
    small: {
        width: "25%",
        height: "auto",
        maxHeight: "900px",
    },
    medium: {
        width: "40%",
        height: "auto",
    },
    large: {
        width: "60%",
        height: "auto",
    },
    extraLarge: {
        width: "90%",
        height: "90%",
    },
};

// Custom hook for window resize
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleResize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return size;
}

const ResponsiveDialog = ({ size = "medium", children, ...props }) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const selectedSize = sizeStyles[size];
    const [dialogSize, setDialogSize] = useState({ ...selectedSize });

    useEffect(() => {
        const updateSize = () => {
            const isWidthNumeric = typeof selectedSize.width === "number";
            const isHeightNumeric = typeof selectedSize.height === "number";

            let newWidth = selectedSize.width;
            let newHeight = selectedSize.height;

            // Adjust width for smaller screens or when modal exceeds viewport width
            if (
                windowWidth < 992 ||
                (isWidthNumeric && windowWidth < selectedSize.width)
            ) {
                newWidth = windowWidth * 0.98; // 98% of window width
            }

            if (isHeightNumeric && windowHeight < selectedSize.height) {
                // Adjust height when modal exceeds viewport height
                newHeight = windowHeight * 0.98; // 98% of window height
            }

            setDialogSize({
                width: isWidthNumeric ? `${newWidth}px` : newWidth, // Ensure unit is added if numeric
                height: isHeightNumeric ? `${newHeight}px` : newHeight,
            });
        };

        updateSize();
    }, [windowWidth, windowHeight, selectedSize]);

    return (
        <Dialog {...props} style={{ padding: 0 }} width={dialogSize.width} height={dialogSize.height}>
            <div style={{ maxHeight: "83vh", overflow: "auto", padding: 16 }}>
                {children}
            </div>
        </Dialog>
    );
};

export default ResponsiveDialog;
