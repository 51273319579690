import React, { useContext, useRef } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import BaseService from "../../../../../services/BaseService";
import { useModal } from "../../../../../hooks/useModal";
import { ReminderModalHelper } from "../../../Reminders/Helpers/ReminderModalHelper";
import { ReminderModal } from "../../../Reminders/Components/ReminderModal";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import { DateCell } from "../../../../../components/GridColumnComponents/DateCell";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";

const LeadRemindersTab = (lead) => {
    const service = useRef(new BaseService("Customer/Reminder"));
    const { triggerReload } = useContext(ReloadDataContext);
    const reminderModal = useModal();

    const onDelete = (id) => {
        service.current.delete(id).then(() => {
            triggerReload();
        });
    };

    const markCompleted = (reminder) => {
        const data = ReminderModalHelper.formatDataForNetwork(reminder);
        data.isCompleted = true;

        service.current.update(reminder).then(() => {
            triggerReload();
        });
    };

    return (
        lead && (
            <div className={"JustifyLeftAlignLeft FlexColumn MediumGap"}>
                <ReminderModal {...reminderModal} />
                <ActionButton
                    text={"Create Reminder"}
                    icon={"k-icon k-i-plus"}
                    onClick={() =>
                        reminderModal.open(undefined, { lead: lead })
                    }
                    requiredPermissions={[PermissionsEnum.CreateReminders]}
                />
                <DataGrid
                    sortable={false}
                    style={{
                        maxWidth: 1050,
                    }}
                    uri={"/Customer/Reminder/GetAll?"}
                    searchFields={["message, employeeWhoCreated.name, date"]}
                    initialSort={[{ field: "date", dir: "desc" }]}
                    initialFilter={{
                        logic: "and",
                        filters: [
                            {
                                field: "leadId",
                                operator: "eq",
                                value: lead.id,
                            },
                            {
                                field: "isCompleted",
                                operator: "eq",
                                value: false,
                            },
                        ],
                    }}
                >
                    <GridColumn
                        field={"message"}
                        title={"Message"}
                        width={450}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field={"date"}
                        title={"Due Date"}
                        width={125}
                        cell={(props) => (
                            <DateCell {...props} format={"MM/DD/YYYY"} />
                        )}
                    />
                    <GridColumn
                        field={"employeeWhoCreated.name"}
                        title={"Created By"}
                        className={"text-overflow"}
                        width={200}
                    />
                    <GridColumn
                        width={250}
                        cell={(props) => (
                            <ActionCell
                                onDelete={() => onDelete(props.dataItem.id)}
                                onEdit={() =>
                                    reminderModal.open(props.dataItem.id)
                                }
                                permissions={{
                                    edit: [PermissionsEnum.EditReminders],
                                    delete: [PermissionsEnum.DeleteReminders],
                                }}
                                additionalActions={[
                                    {
                                        text: "Mark Completed",
                                        onClick: () =>
                                            markCompleted(props.dataItem),
                                        requiredPermissions: [
                                            PermissionsEnum.EditReminder,
                                        ],
                                        confirmationState: {
                                            needsConfirmation: true,
                                            confirmationText:
                                                "Are you sure you wish to mark as complete?",
                                        },
                                    },
                                ]}
                            />
                        )}
                    />
                </DataGrid>
            </div>
        )
    );
};

export default LeadRemindersTab;
