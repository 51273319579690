import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import styles from "../Styles/PermissionExpansionPanel.module.scss";
import StringHelper from "../../../../../../../resources/StringHelper.js";
import { RoleContext } from "../../../Providers/RoleProvider.jsx";

/**
 * @function PermissionExpansionPanel
 * @param props.title {String}
 * @param props.accessList {Object}
 * @param props.panelContent {Array|Element}
 * @return {Element}
 * @constructor
 */
const PermissionExpansionPanel = (props) => {
    const { title, accessList, panelContent } = props;
    const [selected, setSelected] = useState(null);
    const { role } = useContext(RoleContext);

    // Whenever Role changes, clear panels
    useEffect(() => {
        setSelected(null);
    }, [role]);

    // Format the provided access list so it can be used in the JSX easily
    const parsedAccessList = useMemo(() => {
        if (!accessList) return [];
        if (!role) return [];

        const keys = Object.keys(accessList);
        const formattedAccessList = [];

        keys.forEach((key) => {
            formattedAccessList.push({
                title: StringHelper.addSpacesToCapitalLetters(key),
                // We truncate and join here to reduce the possibility of a large amount of permissions
                // taking up the screen
                accessListStr: StringHelper.truncate(
                    accessList[key].join(", "),
                    200
                ),
            });
        });

        return formattedAccessList;
    }, [accessList, role]);

    return (
        <ExpansionPanel
            disabled={!role}
            className={styles.ExpansionPanel}
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        textTransform: "none",
                        color: "black",
                    }}
                >
                    <span
                        style={{
                            fontSize: 16,
                            fontWeight: 600,
                        }}
                    >
                        {title}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                        }}
                    >
                        {role &&
                            selected !== title &&
                            parsedAccessList.map((access) => (
                                <span
                                    key={Math.random()}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        gap: 2,
                                        flexDirection: "column",
                                    }}
                                >
                                    <strong
                                        style={{
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {access.title}
                                    </strong>
                                    <span
                                        style={{
                                            color: "grey",
                                            textAlign: "left",
                                        }}
                                    >
                                        {access.accessListStr}
                                    </span>
                                </span>
                            ))}
                    </span>
                </div>
            }
            expanded={selected === title}
            onAction={(e) => setSelected(e.expanded ? null : title)}
            style={{ width: "100%" }}
        >
            {selected === title && (
                <ExpansionPanelContent>{panelContent}</ExpansionPanelContent>
            )}
        </ExpansionPanel>
    );
};

export default PermissionExpansionPanel;
