import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import { FormInput, FormNumericInput } from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { RadioGroup } from '@progress/kendo-react-inputs';
import { ItemTypeOptions, itemTypeOptionsList } from "../../../../../../resources/Enums/ItemTypeOptions";

import {
    createItemType,
    getItemType,
    updateItemType,
} from "../../../../../../services/Deprecated/inventory/itemService";

export const ItemTypeModal = (props) => {
    const { id, isUpdate, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [key, setKey] = useState(1);
    const [quantityDisabled, setQuantityDisabled] = useState(true);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getItemType(id).then((res) => {
                setInitialFormData(res.data);

                if (res.data.logic === ItemTypeOptions.Enum.Each) {
                    setQuantityDisabled(true);
                } else {
                    setQuantityDisabled(false);
                }
            });
        } else {
            setInitialFormData({});
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onUpdateLogic = (e, frp) => {
        setInitialFormData({
            ...initialFormData,
            name: frp.valueGetter('name'),
            logic: e.value,
            quantity: e.value == 'case' ? 2 : 1
        });

        if (e.value == 'each') {
            setQuantityDisabled(true);
        } else {
            setQuantityDisabled(false);
        }

        setKey(key + 1);
    }

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        if (isUpdate) {
            updateItemType(dataItem).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        } else {
            createItemType(dataItem).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        }
    };

    return (
        visible &&
        initialFormData && (
            <>
                <ResponsiveDialog
                    title={isUpdate ? "Edit ItemType" : "Add ItemType"}
                    onClose={onClose}
                    size={"small"}
                >
                    <Form
                        key={key}
                        ignoreModified={true}
                        initialValues={initialFormData}
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement
                                className={
                                    "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                                }
                            >
                                <div style={{ width: "100%" }}>
                                    <Field
                                        name={"name"}
                                        component={FormInput}
                                        label={"Name"}
                                        validator={requiredValidator}
                                    />
                                </div>
                                <div style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                                    <Field
                                        name={"logic"}
                                        component={RadioGroup}
                                        label={"What logic should this item type follow?"}
                                        data={itemTypeOptionsList}
                                        onChange={e => {
                                            onUpdateLogic(e, formRenderProps)
                                        }}
                                        validator={requiredValidator}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <Field
                                        name={"quantity"}
                                        component={FormNumericInput}
                                        label={"Quantity"}
                                        type="number"
                                        disabled={quantityDisabled}
                                        validator={requiredValidator}
                                        defaultValue={1}
                                        min={1}
                                    />
                                </div>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onClose}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={!isUpdate}
                                />
                            </FormElement>
                        )}
                    />
                </ResponsiveDialog>
            </>
        )
    );
};
