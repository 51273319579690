import React, { useContext, useState } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { JobContext } from "../../../../../../providers/Deprecated/Customer/JobProvider";
import { useModal } from "../../../../../../hooks/useModal";
import { DataGrid } from "../../../../../../components/Grids/DataGrid";
import { LinkCell } from "../../../../../../components/GridColumnComponents/LinkCell";
import { BaseCell } from "../../../../../../components/GridColumnComponents/BaseCell";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum";
import ItemProcessor from "../../../../../../resources/Processors/ItemProcessor.js";
import { AllocationModal } from "./Components/AllocationModal/AllocationModal.jsx";
import { UseModal } from "./Components/UseModal/UseModal.jsx";
import { VendorDocumentModalWrapper } from "../../../../../Inventory/VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { VendorDocumentModalHelper } from "../../../../../Inventory/VendorDocuments/Components/VendorDocumentModal/Helpers/VendorDocumentModalHelper.js";
import {
    ItemTypeEnum,
    NewItemTypeEnum,
} from "../../../../../../resources/Enums/ItemTypeEnum.js";
import { ItemAllocationHelper } from "./Helpers/ItemAllocationHelper.js";
import { getVendorItemsByItemId } from "../../../../../../services/Deprecated/inventory/vendorService.js";

export const ItemAllocationTabPage = () => {
    const { job } = useContext(JobContext);
    const allocationModal = useModal();
    const usedModal = useModal();
    const vdModel = useModal();

    const buildVDLineItem = async (props) => {
        let vendorItem = props.primaryVendorItem;
        let quantityNeeded = props.qtyRemaining;

        if (vendorItem.item?.unitItemParentId) {
            const itemRes = await getVendorItemsByItemId(vendorItem.item?.unitItemParentId);
            vendorItem = itemRes.data.length ? itemRes.data[0] : vendorItem;
        }

        let itemTotal = vendorItem.totalCost.amount * props.qtyRemaining;

        if (vendorItem.item.retailUom.name === "Sqft") {
            const defaultSqft = Math.max(
                ItemAllocationHelper.getItemDefaultSqft(vendorItem.item),
                0
            );
            quantityNeeded = Math.ceil(props.qtyRemaining / defaultSqft);
            itemTotal =
                vendorItem.totalCost.amount * defaultSqft * quantityNeeded;
        }

        if (props.primaryVendorItem.item?.unitItemParentId) {
            quantityNeeded = 1;
            itemTotal = vendorItem.totalCost.amount;
        }

        const lineItem = {
            ...vendorItem,
            job: job,
            total: {
                amount: itemTotal,
                currency: "USD",
            },
            quantity: quantityNeeded,
            vendorItemId: vendorItem.id,
            type: NewItemTypeEnum.Enum.Item,
            id: Math.random(),
        };

        return VendorDocumentModalHelper.formatLineItemForGrid(lineItem);
    };

    return (
        <span>
            <AllocationModal {...allocationModal} />
            <UseModal {...usedModal} />
            <VendorDocumentModalWrapper {...vdModel} />
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={`/Customer/Job/Item/GetAllocationList?id=${job?.id}&`}
                initialSort={[
                    {
                        field: "name",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    className={"text-overflow"}
                    field={"name"}
                    title={"Item:"}
                    width={400}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Inventory/Item/${props.dataItem.itemId}`}
                            dataItem={{
                                id: props.dataItem.itemId,
                                name: props.dataItem.name,
                            }}
                        />
                    )}
                />
                <GridColumn
                    field="qtyNeeded"
                    title="Needed From SO:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {ItemProcessor.formatQuantityForDisplay(
                                props.dataItem.retailUom,
                                props.dataItem.qtyNeeded,
                                "Grid"
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyUsed"
                    title="Used:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {ItemProcessor.formatQuantityForDisplay(
                                props.dataItem.retailUom,
                                props.dataItem.qtyUsed,
                                "Grid"
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyAllocated"
                    title="Allocated:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {ItemProcessor.formatQuantityForDisplay(
                                props.dataItem.retailUom,
                                props.dataItem.qtyAllocated,
                                "Grid"
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyOrderedFromVendor"
                    title="Ordered:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {ItemProcessor.formatQuantityForDisplay(
                                props.dataItem.orderingUom,
                                props.dataItem.qtyOrderedFromVendor,
                                "Grid"
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyInStock"
                    title="In Stock:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyInStock}{" "}
                            {props.dataItem.retailUom.name}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyRemaining"
                    title="Remaining:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyRemaining}{" "}
                            {props.dataItem.retailUom.name}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    width={510}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    text: "Create PO",
                                    icon: "k-icon k-i-plus",
                                    onClick: async () => {
                                        const lineItems = await buildVDLineItem(props.dataItem);

                                        vdModel.open(undefined, {
                                            vendor: props.dataItem
                                                .primaryVendorItem?.vendor,
                                            job: job,
                                            businessInformation:
                                                job.businessInformation,
                                            lineItems: [lineItems],
                                        })
                                    },
                                    requiredPermissions: [
                                        PermissionsEnum.CreateVendorDocument,
                                    ],
                                    disabled:
                                        props.dataItem.qtyInStock -
                                        props.dataItem.qtyRemaining >=
                                        0,
                                    disabledToolTipMessage:
                                        "Item has stock available or has been fully allocated",
                                },
                                {
                                    text: "Use",
                                    icon: "k-icon k-i-plus",
                                    onClick: () =>
                                        usedModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            qtyAllocated:
                                                props.dataItem.qtyAllocated,
                                            qtyUsed: props.dataItem.qtyUsed,
                                            jobId: job.id,
                                        }),
                                    requiredPermissions: [
                                        PermissionsEnum.AllocationInventory,
                                    ],
                                    disabled:
                                        props.dataItem.qtyAllocated <=
                                        props.dataItem.qtyUsed,
                                    disabledToolTipMessage:
                                        props.dataItem.qtyAllocated === 0
                                            ? "Item cannot be used without allocations"
                                            : "Item has been fully used",
                                },
                                {
                                    text: "Un-Use",
                                    icon: "k-icon k-i-minus",
                                    onClick: () =>
                                        usedModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            qtyAllocated:
                                                props.dataItem.qtyAllocated,
                                            qtyUsed: props.dataItem.qtyUsed,
                                            jobId: job.id,
                                            isUnUsed: true,
                                        }),
                                    requiredPermissions: [
                                        PermissionsEnum.AllocationInventory,
                                    ],
                                    disabled: props.dataItem.qtyUsed === 0,
                                    disabledToolTipMessage:
                                        "Item has not been used",
                                },
                                {
                                    text: "Allocate",
                                    icon: "k-icon k-i-plus",
                                    onClick: () =>
                                        allocationModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            remainingNeeded:
                                                props.dataItem.qtyRemaining,
                                            jobId: job.id,
                                        }),
                                    requiredPermissions: [
                                        PermissionsEnum.AllocationInventory,
                                    ],
                                    disabled:
                                        props.dataItem.qtyRemaining <= 0 ||
                                        props.dataItem.qtyInStock === 0,
                                    disabledToolTipMessage:
                                        props.dataItem.qtyInStock === 0
                                            ? "Item cannot be allocated without stock"
                                            : "Item has been fully allocated",
                                },
                                {
                                    text: "Return To Stock",
                                    icon: "k-icon k-i-minus",
                                    onClick: () =>
                                        allocationModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            remainingNeeded:
                                                props.dataItem.qtyAllocated,
                                            jobId: job.id,
                                            isDeallocate: true,
                                        }),
                                    requiredPermissions: [
                                        PermissionsEnum.AllocationInventory,
                                    ],
                                    disabled:
                                        props.dataItem.qtyAllocated -
                                        props.dataItem.qtyUsed ===
                                        0,
                                    disabledToolTipMessage:
                                        "Item has no allocations to return",
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
