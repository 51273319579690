import React, { useContext } from "react";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { GridColumn } from "@progress/kendo-react-grid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { CustomerTypeEnum } from "../../../resources/Enums/CustomerStatusEnum";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import LeadService from "../../../services/LeadService";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";

const DeadLeadsDashboardPage = () => {
    const { triggerReload } = useContext(ReloadDataContext);
    const onMarkActive = (id) => {
        const service = new LeadService("Customer/Lead");
        service.updateStatus(id, 2).then(() => {
            triggerReload();
        });
    };

    return (
        <DataGrid
            style={{ maxWidth: 1225 }}
            sortable={false}
            uri={"/Customer/Lead/GetAll?"}
            initialFilter={{
                logic: "and",
                filters: [
                    {
                        field: "OnHold.Value",
                        operator: "eq",
                        value: "false",
                    },
                    {
                        field: "IsDead.Value",
                        operator: "eq",
                        value: "true",
                    },
                ],
            }}
            initialSort={[{ field: "customer.name2", dir: "asc" }]}
            searchFields={["customer.name1", "customer.name2"]}
        >
            <GridColumn field={"combinedName"} title={"Name"} width={300} />
            <GridColumn
                field={"type"}
                title={"Type"}
                width={150}
                cell={(props) => (
                    <EnumCell {...props} enumerable={CustomerTypeEnum} />
                )}
            />
            <GridColumn
                field={"primaryContact.email"}
                title={"Email"}
                width={250}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`mailto:${props.dataItem?.primaryContact?.email}`}
                        text={props.dataItem?.primaryContact?.email}
                    />
                )}
            />
            <GridColumn
                field={"primaryContact.phone.phoneNumber"}
                title={"Phone"}
                width={150}
            />
            <GridColumn
                width={180}
                cell={(props) => (
                    <ActionCell
                        additionalActions={[
                            {
                                onClick: () => onMarkActive(props.dataItem.id),
                                text: "Mark as Active",
                                confirmationState: {
                                    needsConfirmation: true,
                                    confirmationText:
                                        "Are you sure you want to mark this lead as active?",
                                },
                                requiredPermissions: [
                                    PermissionsEnum.EditLead,
                                ],
                            },
                        ]}
                    />
                )}
            />
        </DataGrid>
    );
};

export default DeadLeadsDashboardPage;
