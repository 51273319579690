import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import CommunicationCreationComponent from "./CommunicationCreationComponent";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteCustomerCommunication } from "../../../../services/Deprecated/customer/customerServices";
import CustomerCommunicationEdit from "./CustomerCommunicationEdit";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const CommunicationDetails = () => {
    const customerContext = useContext(CustomerContext);

    return customerContext.customer.id ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            <span style={{ marginLeft: "5px" }}>
                <CommunicationCreationComponent />
            </span>
            <DataGrid
                className="card-container"
                uri={`/Customer/Customer/Communication/GetAll?customerId=${customerContext.customer.id}&`}
                style={{
                    maxHeight: "400px",
                }}
            >
                <GridColumn field="note" title="Note:" />
                <GridColumn
                    field="created"
                    title="Date Created:"
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.created}
                        />
                    )}
                />
                <GridColumn
                    field="lastModified"
                    title="Last Modified:"
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.lastModified}
                        />
                    )}
                />
                <GridColumn
                    width={140}
                    cell={(props) => (
                        <ActionButtonsCommandCell
                            record={props.dataItem}
                            dataId={props.dataItem.id}
                            action={deleteCustomerCommunication}
                            EditComponent={CustomerCommunicationEdit}
                            requiredPermissionsEdit={[
                                PermissionsEnum.EditCustomer,
                            ]}
                            requiredPermissionsAction={[
                                PermissionsEnum.DeleteCustomer,
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default CommunicationDetails;
