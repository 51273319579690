import React, { useContext, useState } from "react";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import clone from "just-clone";
import { RemoteDropDown } from "../../../../../../components/FormInputs/RemoteDropDown.jsx";

/**
 * @function JobDocumentAddLineItemModal
 * @description Modal to add a line item to the document
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const JobDocumentAddLineItemModal = (props) => {
    const { close, visible, record } = props;
    const { addLineItem } = useContext(DocumentLineItemContext);
    const [item, setItem] = useState(undefined);

    const onClose = () => {
        setItem(undefined);
        close();
    };

    const onSubmit = () => {
        addLineItem(
            {
                id: Math.random(), // Ensures unique key on the client side
                type: NewItemTypeEnum.Enum.Item,
                item: { ...item, isActive: true },
                price: item.retailPrice,
                total: clone(item.retailPrice),
                isDetailItem: item.isDetailItem,
                isPackage: item.isPackage,
                isActive: true,
                description: item.baseDescription,
                quantity: 1,
                IN_LINE_EDIT: true,
            },
            record?.index
        );
        onClose();
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Add Line Item"}
                onClose={onClose}
                size={"small"}
            >
                <RemoteDropDown
                    url={"/Customer/Job/Item/GetAll?"}
                    label={"Item:"}
                    useExactMatch={true}
                    initialFilters={[
                        {
                            field: "isActive",
                            operator: "eq",
                            value: true,
                        },
                        {
                            field: "isStockOrSupplyItem",
                            operator: "eq",
                            value: false,
                        },
                        {
                            field: "useInPackage",
                            operator: "eq",
                            value: false,
                        }
                    ]}
                    dataMutator={(data) => {
                        return data.filter(i => i.retailUom?.name !== 'N/A');
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => setItem(e.value)}
                />
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={item !== undefined}
                    isCreate={true}
                    text={"Add"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
