import * as React from "react";
import { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    PanelBar,
    PanelBarItem,
} from "@progress/kendo-react-layout";
import DashboardService from "../../services/Deprecated/reporting/DashboardService";
import { PermissionsEnum } from "../../resources/Enums/PermissionsEnum";
import ActiveTasks from "./Components/ActiveTasks";
import TrafficReport from "./Components/TrafficReport";
import BalanceDue from "./Components/BalanceDue";
import NoBalanceDue from "./Components/NoBalanceDue";
import RemindersReport from "./Components/RemindersReport";
import OutstandingItemReceipts from "./Components/OutstandingItemReceipts";
import { useUserPermissions } from "../../hooks/useUserPermissions.js";
import PermissionsHelper from "../../resources/PermissionsHelper.js";
import EventDatesQueue from "./Components/EventDatesQueue.jsx";
import ServiceDatesQueue from "./Components/ServicedDatesQueue.jsx";

const ReportingDashboardPage = () => {
    const [data, setData] = useState(undefined);
    const userPermissions = useUserPermissions();

    const onSelect = async (props) => {
        setData(undefined);
        if (props.expandedItems.length > 1) {
            throw new Error("Expanded Items cannot be greater than one.");
        }

        if (props.expandedItems.length === 0) {
            return;
        }

        const expandedItem = props.expandedItems[0];

        switch (expandedItem) {
            case "ActiveTasks":
                DashboardService.getCurrentActiveTasks().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            case "TrafficReport":
                DashboardService.getTrafficReport().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            case "BalanceDue":
                DashboardService.getJobsWithMoneyDueReport().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            case "OutstandingItemReceipts": {
                const fullData =
                    await DashboardService.getFullOutstandingItemReceiptsReport();
                const limitedData =
                    await DashboardService.getLimitedOutstandingItemReceiptsReport();
                setData({
                    limitedData: limitedData.data,
                    fullData: fullData.data,
                });
                break;
            }
            case "NoBalanceDue":
                DashboardService.getJobsWithNoMoneyDueReport().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            case "EventDatesQueue":
                DashboardService.getEventDatesQueueReport().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            case "ServiceDatesQueue":
                DashboardService.getServiceDatesQueueReport().then((res) => {
                    if (!res) return;
                    setData(res.data);
                });
                break;
            default:
                break;
        }
    };

    return (
        <Card id={"reporting"}>
            <CardHeader>
                <div>
                    <CardTitle>
                        <strong
                            style={{ textTransform: "uppercase" }}
                            className={"JustifyLeftAlignCenter SmallGap"}
                        >
                            <i className={"k-icon k-i-gear"}></i>
                            Dashboard
                        </strong>
                    </CardTitle>
                </div>
            </CardHeader>
            <CardBody>
                <PanelBar expandMode={"single"} onSelect={onSelect}>
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewCurrentActiveTasks,
                    ]) && (
                        <PanelBarItem
                            title={"Current Active Tasks"}
                            id={"ActiveTasks"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <ActiveTasks data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewTrafficReport,
                    ]) && (
                        <PanelBarItem
                            title={"Traffic Report"}
                            id={"TrafficReport"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <TrafficReport data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewBalanceDueNoFutureEvents,
                    ]) && (
                        <PanelBarItem
                            title={"Balance Due - No Future Events"}
                            id={"BalanceDue"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <BalanceDue data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewNoBalanceDueNoFutureEvents,
                    ]) && (
                        <PanelBarItem
                            title={"No Balance Due - No Future Events"}
                            id={"NoBalanceDue"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <NoBalanceDue data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewReminders,
                    ]) && (
                        <PanelBarItem title={"Reminders"} id={"Reminders"}>
                            <div className={"ReportPanelContainer"}>
                                <RemindersReport />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewOutstandingItemReceipts,
                    ]) && (
                        <PanelBarItem
                            title={"Outstanding PO Item Receipts"}
                            id={"OutstandingItemReceipts"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <OutstandingItemReceipts data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewEventDatesQueue,
                    ]) && (
                        <PanelBarItem
                            title={"Event Dates Queue"}
                            id={"EventDatesQueue"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <EventDatesQueue data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                    {PermissionsHelper.hasPermission(userPermissions, [
                        PermissionsEnum.ViewServiceDatesQueue,
                    ]) && (
                        <PanelBarItem
                            title={"Service Dates Queue"}
                            id={"ServiceDatesQueue"}
                        >
                            <div className={"ReportPanelContainer"}>
                                <ServiceDatesQueue data={data} />
                            </div>
                        </PanelBarItem>
                    )}
                </PanelBar>
            </CardBody>
        </Card>
    );
};

export default ReportingDashboardPage;
