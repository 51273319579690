import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Stepper } from "@progress/kendo-react-layout";
import ItemInformationForm from "./ItemInformationForm";
import MultiItemForm from "./MultiItemForm";
import clone from "just-clone";
import { ItemRelationshipsContext } from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { createItems } from "../../../../services/Deprecated/inventory/itemService";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { ToastContext } from "../../../../providers/ToastProvider.jsx";

const InitialStepState = [
    {
        label: "Base Information",
        isValid: undefined,
    },
    {
        label: "Multi Item",
        isValid: undefined,
    },
];

// This is the component that will be rendered when the user clicks the create button
const ItemCreationComponent = ({
    selectedItem,
    visible,
    close,
    id,
    record,
}) => {
    const { showToast } = useContext(ToastContext);
    const rc = useContext(ItemRelationshipsContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const [formSize, setFormSize] = useState("large");
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [step, setStep] = useState(0);
    const [formState, setFormState] = useState({
        vendorId: id,
        vendor: record,
        isActive: true,
        categoryId: selectedItem.id,
        category: selectedItem,
    });
    const [steps, setSteps] = useState(InitialStepState);
    const lastStepIndex = steps.length - 1;

    useEffect(() => {
        if (step === 0) {
            setFormSize("medium");
        } else {
            setFormSize("extraLarge");
        }
    }, [step]);

    // Add CustomizationLists to the relationships, so we can gain access to it in the child component
    useEffect(() => {
        if (!visible) return;

        rc.relationships.customizationLists = selectedItem.customizationLists;
        rc.setRelationships(rc.relationships);

        setFormState({
            vendorId: id,
            vendor: record,
            isActive: true,
            categoryId: selectedItem.id,
            category: selectedItem,
        });
    }, [visible]);

    /**
     Callback function triggered when a step is submitted. This function sets the steps and
     determines if a step is valid or not. It also will submit the item if the last step is valid.

     @param {Object} event - The event object containing information about the step submission.
     */
    const onStepSubmit = (event) => {
        const stepBeforeSubmit = step;
        const { isValid, values } = event;
        const buttonClicked = event?.event?.nativeEvent?.submitter?.innerText;
        const currentSteps = steps.map((cs, index) => ({
            ...cs,
            isValid: index === step ? isValid : cs.isValid,
        }));

        setSteps(currentSteps);

        if (!isValid) return;

        // If initial step and the button is "Create", then it is
        // deemed as a single item
        if (step === 0 && buttonClicked === "Create") {
            onInitialStepSubmit({
                values,
                isFormValid: isValid,
            });
            return;
        }

        setStep(() => Math.min(step + 1, lastStepIndex));

        setFormState(values);

        if (lastStepIndex === step && buttonClicked === "Create") {
            submitItem(values);
        }
    };

    const onInitialStepSubmit = (formData) => {
        if (!formData.values.name) {
            onCreatingMutliItems(formData);
        } else {
            onCreatingSingleItem(formData);
        }
    }

    const onCreatingSingleItem = (formData) => {

        if (formData.isFormValid) {
            submitItem({
                ...formData.values,
                items: [
                    {
                        retailPrice: formData.values.retailPrice,
                        vendorCost: formData.values.vendorCost,
                        freightCharge: formData.values.freightCharge,
                        name: formData.values.name,
                        baseDescription:
                            formData.values.baseDescription,
                        purchaseOrderDescription:
                            formData.values.purchaseOrderDescription,
                    },
                ],
            });
        }
    };

    const onCreatingMutliItems = (formData) => {
        onStepSubmit({
            isValid: formData.isFormValid,
            values: formData.values,
            event: { nativeEvent: { submitter: { innerText: "No" } } },
        });
    };

    /**
     Function that will be called when the user clicks the previous button.

     @param {Object} event - The event object containing information about the prev step submission.
     */
    const onPrevClick = React.useCallback(() => {
        setStep(() => Math.max(step - 1, 0));
    }, [step, setStep]);

    const resetForm = () => {
        close();
        setFormState(undefined);
        setStep(0);
        setSteps(InitialStepState);
    };

    /**
     * Function that will be called when the user submits the vendor form.
     * @Method
     * @param {{
     * itemType: Object,
     * orderingUom: Object,
     * retailUom: Object,
     * vendor: Object,
     * itemTypeId: string,
     * unitOfMeasureId: string,
     * retailUomId: string,
     * orderingUomId: string,
     * categoryId: string,
     * vendorId: string,
     * items: array
     * item: Object,
     * defaultLength: number,
     * defaultWidth: number,
     * isActive: boolean,
     * isStockOrSupplyItem: boolean
     * }} dataItem - Dropdown Data from API.
     */
    const submitItem = async (dataItem) => {
        toggleLoaderVisible();
        const formData = clone(dataItem);

        const items = formData.items.map((item) => {
            return {
                vendorId: formData?.vendor?.id,
                itemTypeId: formData?.itemType?.id,
                categoryId: formData.categoryId,
                orderingUomId: formData?.orderingUom?.id,
                retailUomId: formData?.retailUom?.id,
                unitOfMeasureId: formData?.unitOfMeasure?.id,
                expenseAccountId: formData?.expenseAccount?.id,
                incomeAccountId: formData?.incomeAccount?.id,
                sku: formData.manufacturerNumber,
                defaultLength: formData.defaultLength,
                defaultWidth: formData.defaultWidth,
                isActive: !!formData.isActive,
                isStockOrSupplyItem: !!formData.isStockOrSupplyItem,
                isDescriptionEditAllowed: !!formData.isDescriptionEditAllowed,
                baseDescription: item.baseDescription || "",
                purchaseOrderDescription: item.purchaseOrderDescription,
                name: item.name,
                yield: formData.yield ?? 1,
                units: formData.units ?? 1,
                unitItemId: formData?.unitItem?.id,
                retailPrice: {
                    amount: item.retailPrice,
                    currency: "USD",
                },
                vendorCost: {
                    amount: item?.vendorCost ?? 0,
                    currency: "USD",
                },
                freightCharge: {
                    amount: item?.freightCharge ?? 0,
                    currency: "USD",
                },
            };
        });

        const requestObject = { items };

        const serviceResponse = await createItems(requestObject);

        if (serviceResponse.success) {
            reloadDataContext.triggerReload();
            resetForm();
        }
        toggleLoaderVisible();
    };

    return (
        <div>
            {visible && formState && (
                <span>
                    <Form
                        onSubmitClick={onStepSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Item"}
                                onClose={() => resetForm()}
                                size={formSize}
                            >
                                <Stepper value={step} items={steps} />
                                <FormElement>
                                    {step === 0 && (
                                        <ItemInformationForm
                                            formRenderProps={formRenderProps}
                                            itemCount={"multi"}
                                            showCategory={false}
                                            create={true}
                                        />
                                    )}
                                    {step === 1 && (
                                        <MultiItemForm
                                            formRenderProps={formRenderProps}
                                            categoryId={formState.categoryId}
                                        />
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            {!formRenderProps.valueGetter('name') && (<>Step {step + 1} of 2</>)}
                                        </span>
                                        <FormButtons
                                            isCreate={true}
                                            allowSubmit={
                                                formRenderProps.allowSubmit
                                            }
                                            text={"Create"}
                                            loaderVisible={loaderVisible}
                                            theme={"primary"}
                                            actionOnCancel={() => resetForm()}
                                            stepState={{
                                                isLastStep:
                                                    lastStepIndex === step || !!formRenderProps.valueGetter('name'),
                                                currentStep: step,
                                                onPreviousStepSubmit:
                                                    onPrevClick,
                                            }}
                                        />
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};

export default ItemCreationComponent;
