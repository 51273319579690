import React, { useState } from "react";
import PaymentFormModal from "./PaymentFormModal";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const PaymentCreateBtn = () => {
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [visible, setVisible] = useState(false);

    //? Display a button that opens the PaymentFormModal
    return (
        <div>
            <ActionButton
                requiredPermissions={[PermissionsEnum.CreateCustomerPayments]}
                icon={"k-icon k-i-plus"}
                onClick={() => setVisible(true)}
                style={{
                    margin: "5px",
                }}
            >
                Add Payment/Refund
            </ActionButton>
            <PaymentFormModal
                visible={visible}
                onCancel={() => setVisible(false)}
                loaderVisible={loaderVisible}
                toggleLoaderVisible={toggleLoaderVisible}
            />
        </div>
    );
};

export default PaymentCreateBtn;
