import "@progress/kendo-theme-default/dist/default-ocean-blue.scss";
import "./styles/common.scss";
import { HashRouter, Route, Switch } from "react-router-dom";
import {
    AuthenticatedTemplate,
    useMsalAuthentication,
} from "@azure/msal-react";
import React, { useContext, useEffect } from "react";
import ItemCategoryWrapper from "./pages/Inventory/ItemCategory/ItemCategoryPage";
import { InteractionType } from "@azure/msal-browser";
import { Loader } from "@progress/kendo-react-indicators";
import ReloadDataProvider from "./providers/ReloadDataProvider";
import TimeClockProvider from "./providers/Deprecated/HumanResources/TimeClock/TimeClockProvider";
import VendorProvider from "./providers/Deprecated/Inventory/Vendor/VendorProvider";
import InventoryProvider from "./providers/Deprecated/Inventory/InventoryProvider";
import CustomerProvider from "./providers/Deprecated/Customer/CustomerProvider";
import EventTypeProvider from "./providers/Deprecated/Authentication/EventTypeProvider";
import UserProvider from "./providers/Deprecated/Authentication/User/UserProvider";
import EmployeeProvider from "./providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import PermissionListBoxProvider from "./providers/Deprecated/Authentication/Permission/PermissionListBoxProvider";
import JobProvider from "./providers/Deprecated/Customer/JobProvider";
import EventProvider from "./providers/Deprecated/Customer/EventProvider";
import AccountingProvider from "./pages/Accounting/Providers/AccountingProvider";
import AuthProvider, {
    AuthContext,
} from "./providers/Deprecated/Authentication/User/AuthProvider";
import { ToastComponent } from "./components/ToastComponent";
import BreadCrumbProvider from "./providers/BreadCrumbProvider";
import NavigationSideBar from "./components/NavigationSideBar";
import BreadCrumb from "./components/BreadCrumb";
import PermissionRoute from "./routes/RouteConfig";
import ReportingDashboardPage from "./pages/Reporting/ReportingDashboardPage";
import RoleDashboardPage from "./pages/Admin/Security/Role/RoleDashboardPage";
import AdminUserDetailsPage from "./pages/Admin/Security/User/AdminUserDetailsPage";
import CustomerTabPage from "./pages/Admin/Configuration/Customer/CustomerTabPage";
import GeneralTabPage from "./pages/Admin/Configuration/General/GeneralTabPage";
import ChartOfAccountsPage from "./pages/Accounting/ChartOfAccounts/ChartOfAccountsPage";
import AccountingJournalPage from "./pages/Accounting/AccountingJournal/AccountingJournalPage";
import BalanceSheetPage from "./pages/Accounting/BalanceSheet/BalanceSheetPage";
import IncomeStatementPage from "./pages/Accounting/IncomeStatement/IncomeStatementPage";
import ItemDashboardPage from "./pages/Inventory/Item/ItemDashboardPage";
import ItemDetailsPageWrapper from "./pages/Inventory/Item/ItemDetailsPage";
import InventoryDashboardPage from "./pages/Inventory/Inventory/InventoryDashboardPage";
import EmployeeDetailsPage from "./pages/HumanResources/Employee/EmployeeDetailsPage";
import VendorDetailsPageWrapper from "./pages/Inventory/Vendor/VendorDetailsPage";
import VendorDashboardPage from "./pages/Inventory/Vendor/VendorDashboardPage";
import SignInButton from "./pages/Login";
import { TimeClockPage } from "./pages/HumanResources/TimeClockEntry/TimeClockPage";
import CustomerDetailsPage from "./pages/Customer/Customers/CustomerDetailsPage";
import { CustomerDashboardPage } from "./pages/Customer/Customers/CustomerDashboardPage";
import LeadDashboardTabs from "./pages/Customer/Leads/Components/LeadDashboardTabs";
import LeadDetailPage from "./pages/Customer/Leads/LeadDetailPage";
import EventDetailsWrapper from "./pages/Customer/CalendarEvents/EventDetailsPage";
import EventDetailsPage from "./pages/Customer/CalendarEvents/EventDetailsPage";
import JobDetailsPage from "./pages/Customer/Jobs/JobDetailsPage";
import { Payroll } from "./pages/Reporting/Payroll/Payroll";
import MyWorkProvider from "./pages/HumanResources/MyWork/MyWorkContext";
import { MyWorkPageWrapper } from "./pages/HumanResources/MyWork";
import NoTenantPage from "./pages/NoTenantPage";

// importing fake styles from some more complex :global based modules
import accountingModuleStyle from "./pages/Accounting/AccountingModule.module.scss";
import humanResourcesModuleStyle from "./pages/HumanResources/HumanResourcesModule.module.scss";
import timeCardModuleStyle from "./pages/HumanResources/TimeClockEntry/TimeCard.module.scss";
import timeClockModuleStyle from "./pages/HumanResources/TimeClockEntry/TimeClockPage.module.scss";
import itemInventoryModuleStyle from "./pages/Inventory/Item/Components/ItemInventoryInformation/ItemInventoryInformation.module.scss";
import inventoryVendorModuleStyles from "./pages/Inventory/Vendor/VendorModule.module.scss";
import reportingModuleStyles from "./pages/Reporting/reporting.module.scss";
import WrappedSchedulerPage from "./pages/Customer/Scheduler/SchedulerPage.jsx";
import InventoryTabPage from "./pages/Admin/Configuration/Inventory/InventoryTabPage.jsx";
import EmployeeTabPage from "./pages/Admin/Configuration/Employee/EmployeeTabPage.jsx";
import PermissionsHelper from "./resources/PermissionsHelper.js";
import InventoryItemDetailPage from "./pages/Inventory/Inventory/InventoryItemDetailPage.jsx";
import ErrorProvider from "./providers/ErrorProvider.jsx";
import { FastFindPage } from "./pages/FastFind/FastFindPage.jsx";
import { PermissionsEnum } from "./resources/Enums/PermissionsEnum.js";
import EmployeeDashboardTabs from "./pages/HumanResources/Employee/EmployeeDashboardTabs.jsx";
import UserDashboardTabs from "./pages/Admin/Security/User/Components/UserDashboardTabs.jsx";

//? Builds up a provider component tree w/o us having to have a hugely nested component
//? Uses the `ProviderTree` component below, and iteratively builds the tree in reverse order
//* more info here: https://javascript.plainenglish.io/embrace-react-composition-for-cleaner-code-a-better-alternative-to-provider-wrapper-hell-ead75512091a
const buildProvidersTree = (componentsWithProps) => {
    const initialComponent = ({ children }) => <>{children}</>;
    return componentsWithProps.reduce(
        (AccumulatedComponents, [Provider, props = {}]) => {
            const WrappedProvider = ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <Provider {...props}>{children}</Provider>
                    </AccumulatedComponents>
                );
            };

            WrappedProvider.displayName = `Enhanced(${
                Provider.displayName || Provider.name || "Provider"
            })`;
            WrappedProvider.displayName = `Enhanced(${
                Provider.name || Provider.displayName || "Provider"
            })`;

            return WrappedProvider;
        },
        initialComponent
    );
};

//! Nesting in order!
//! The highest in the list is the highest in the component tree
const ProviderTree = buildProvidersTree([
    [ReloadDataProvider],
    [TimeClockProvider],
    [VendorProvider],
    [InventoryProvider],
    [CustomerProvider],
    [EventTypeProvider],
    [UserProvider],
    [EmployeeProvider],
    [PermissionListBoxProvider],
    [JobProvider],
    [EventProvider],
    [AccountingProvider],
]);

function App() {
    const { result, error, login } = useMsalAuthentication(
        InteractionType.Redirect
    );

    useEffect(() => {
        if (error) {
            login().then((res) => console.log("recall login res", res));
        }
    }, [error, login]);

    // prevent any sections of the app loading while user is unauthenticated
    // handle all auth logic in unauthenticated section
    return (
        <React.Fragment>
            {result ? (
                <AuthenticatedTemplate>
                    <AuthProvider>
                        <AuthenticatedAppWrapper />
                    </AuthProvider>
                </AuthenticatedTemplate>
            ) : (
                <UnauthenticatedApp />
            )}
        </React.Fragment>
    );
}

function AuthenticatedApp() {
    return (
        <ProviderTree>
            <div
                className={[
                    accountingModuleStyle.ImportFaker,
                    humanResourcesModuleStyle.ImportFaker,
                    timeCardModuleStyle.ImportFaker,
                    timeClockModuleStyle.ImportFaker,
                    itemInventoryModuleStyle.ImportFaker,
                    inventoryVendorModuleStyles.ImportFaker,
                    reportingModuleStyles.ImportFaker,
                ].join(" ")}
            ></div>
            <ToastComponent />
            <HashRouter>
                {/* BreadCrumbProvider placed here so we have access to HashRouter */}
                <BreadCrumbProvider>
                    <ErrorProvider>
                        <NavigationSideBar>
                            {/* BreadCrumb placed here so it is always in the same spot */}
                            <BreadCrumb />
                            <Switch>
                                <Route
                                    exact={true}
                                    path="/"
                                    component={ReportingDashboardPage}
                                />
                                <Route
                                    exact={true}
                                    path="/FastFind"
                                    component={FastFindPage}
                                    requiredPermissions={[
                                        PermissionsEnum.ViewVendorDocument,
                                        PermissionsEnum.ViewJobDocument,
                                    ]}
                                />
                                {/* ADMIN */}
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Security/Roles"
                                    component={RoleDashboardPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_ROLE_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Security/Users"
                                    component={UserDashboardTabs}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_USER_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Security/Users/:id"
                                    component={AdminUserDetailsPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_USER_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Configuration/Customer"
                                    component={CustomerTabPage}
                                    optionalPermissions={
                                        PermissionsHelper.NAV_CUSTOMER_CONFIG_OPTIONAL_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Configuration/Employee"
                                    component={EmployeeTabPage}
                                    optionalPermissions={
                                        PermissionsHelper.NAV_EMPLOYEE_CONFIG_OPTIONAL_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Configuration/Inventory"
                                    component={InventoryTabPage}
                                    optionalPermissions={
                                        PermissionsHelper.NAV_INVENTORY_CONFIG_OPTIONAL_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Admin/Configuration/General"
                                    component={GeneralTabPage}
                                    optionalPermissions={
                                        PermissionsHelper.NAV_GENERAL_CONFIG_OPTIONAL_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Accounting/CoA"
                                    component={ChartOfAccountsPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_COA_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Accounting/Journal"
                                    component={AccountingJournalPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_JOURNAL_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Accounting/BalanceSheet"
                                    component={BalanceSheetPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_BALANCE_SHEET_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Accounting/IncomeStatement"
                                    component={IncomeStatementPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_INCOME_STATEMENT_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Inventory/Category"
                                    component={ItemCategoryWrapper}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_ITEM_CATEGORIES_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Inventory/Item"
                                    component={ItemDashboardPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_ALL_ITEMS_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Inventory/Item/:id"
                                    component={ItemDetailsPageWrapper}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_ALL_ITEMS_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Inventory/Dashboard"
                                    component={InventoryDashboardPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_INVENTORY_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Inventory/Dashboard/Item/:id"
                                    component={InventoryItemDetailPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_INVENTORY_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/HumanResources/Employee"
                                    component={EmployeeDashboardTabs}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_EMPLOYEE_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/HumanResources/Employee/:id"
                                    component={EmployeeDetailsPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_EMPLOYEE_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/inventory/vendor/:id"
                                    component={VendorDetailsPageWrapper}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_VENDORS_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/inventory/vendor"
                                    component={VendorDashboardPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_VENDORS_REQUIRED_PERMISSIONS
                                    }
                                />
                                <Route
                                    exact={true}
                                    path="/login"
                                    component={SignInButton}
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/HumanResources/TimeClock"
                                    component={TimeClockPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_TIME_CLOCK_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/customer/:id"
                                    component={CustomerDetailsPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_CUSTOMER_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/customer"
                                    component={CustomerDashboardPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_CUSTOMER_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Lead/"
                                    component={LeadDashboardTabs}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_LEADS_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Lead/:id"
                                    component={LeadDetailPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_LEADS_DASHBOARD_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/event/:id"
                                    component={EventDetailsWrapper}
                                    requiredPermissions={
                                        PermissionsHelper.ViewCalendarEvent
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/customer/:customerId/job/:id"
                                    component={JobDetailsPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_JOB_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/customer/:customerId/job/:jobId/event/:id"
                                    component={EventDetailsPage}
                                    requiredPermissions={
                                        PermissionsHelper.ViewCalendarEvent
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/Payroll"
                                    component={Payroll}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_PAYROLL_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/scheduler"
                                    component={WrappedSchedulerPage}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_SCHEDULER_REQUIRED_PERMISSIONS
                                    }
                                />
                                <PermissionRoute
                                    exact={true}
                                    path="/MyWork"
                                    component={() => {
                                        return (
                                            <MyWorkProvider>
                                                <MyWorkPageWrapper />
                                            </MyWorkProvider>
                                        );
                                    }}
                                    requiredPermissions={
                                        PermissionsHelper.NAV_MY_WORK_REQUIRED_PERMISSIONS
                                    }
                                />
                            </Switch>
                        </NavigationSideBar>
                    </ErrorProvider>
                </BreadCrumbProvider>
            </HashRouter>
        </ProviderTree>
    );
}

function NoTenantApp() {
    return (
        <HashRouter>
            <Switch>
                <Route exact={true} path="/" component={NoTenantPage} />
            </Switch>
        </HashRouter>
    );
}

function AuthenticatedAppWrapper() {
    // wrapper provides extra checks / logic to wait til the user is loaded into State
    const { user, userLoaded } = useContext(AuthContext);
    return (
        <div className="app">
            {!userLoaded ? (
                <UnauthenticatedApp />
            ) : user?.activeTenantId ? (
                <AuthenticatedApp />
            ) : (
                <NoTenantApp />
            )}
        </div>
    );
}

function UnauthenticatedApp() {
    return (
        <div
            className="app"
            style={{
                backgroundColor: "#364150",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Loader type="infinite-spinner" />
            <h4 style={{ color: "white" }}>
                Loading... This could take upwards of 10 seconds to complete
            </h4>
        </div>
    );
}

export default App;
