import React, { useContext, useState } from "react";
import { MyWorkContext } from "./MyWorkContext";
import { MyWorkDashboard } from "./Components/Dashboard";
import { MyWorkDetails } from "./Components/Details";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import styles from "./MyWork.module.scss";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { MyWorkAdminPanel } from "./Components/AdminPanel";
import PermissionsHelper from "../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../hooks/useUserPermissions.js";
import { ActionButton } from "../../../components/Buttons/ActionButton.jsx";

export const MyWorkPageWrapper = () => {
    const {
        isClockedIn,
        isLoading,
        isOnCalendarEvent,
        currentEmployee,
        toggleClockIn,
        isAdminPanelVisible,
    } = useContext(MyWorkContext);
    const [clockInLoading, setClockInLoading] = useState(false);
    const hasCreatePermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        [PermissionsEnum.CreateTimeClock]
    );
    const hasAdminPermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        [PermissionsEnum.CreateTimeClock]
    );

    const onClockInClick = () => {
        setClockInLoading(true);
        toggleClockIn().then(() => setClockInLoading(false));
    };

    if (isLoading) {
        return renderLoading();
    }

    if (!isClockedIn) {
        return renderClockIn(
            hasCreatePermission,
            hasAdminPermission,
            clockInLoading,
            onClockInClick,
            currentEmployee
        );
    }

    if (hasAdminPermission && isAdminPanelVisible) {
        return renderAdminPanel();
    }

    if (isOnCalendarEvent) {
        return renderOnCalendarEvent();
    }

    if (isClockedIn) {
        return renderDashboard();
    }

    return renderDefault(currentEmployee);
};

function renderLoading() {
    return (
        <div className={styles.MyWorkContainer}>
            <h4>Loading Employee Work Information...</h4>
            <CenterLoader />
        </div>
    );
}

function renderClockIn(
    hasCreatePermission,
    hasAdminPermission,
    clockInLoading,
    onClockInClick,
    currentEmployee
) {
    if (!hasCreatePermission && !hasAdminPermission) {
        return (
            <div className={styles.MyWorkContainer}>
                <h4>
                    {currentEmployee.fullName} must clock in at stationary time
                    clock
                </h4>
            </div>
        );
    }

    return (
        <div className={styles.MyWorkContainer}>
            <h4>
                {currentEmployee.fullName} is not clocked in, do you wish to
                clock in?
            </h4>
            {clockInLoading ? (
                <CenterLoader />
            ) : (
                <ActionButton
                    themeColor="success"
                    onClick={onClockInClick}
                    style={{
                        fontSize: "1.2rem",
                        width: "100px",
                    }}
                >
                    Clock In
                </ActionButton>
            )}
        </div>
    );
}

function renderOnCalendarEvent() {
    return (
        <div className={styles.MyWorkContainer}>
            <MyWorkDetails />
        </div>
    );
}

function renderAdminPanel() {
    return (
        <div className={styles.MyWorkContainer}>
            <MyWorkAdminPanel />
        </div>
    );
}

function renderDashboard() {
    return (
        <div className={styles.MyWorkContainer}>
            <MyWorkDashboard />
        </div>
    );
}

function renderDefault(currentEmployee) {
    return (
        <div className={styles.MyWorkContainer}>
            <h4>{currentEmployee.fullName} see your supervisor.</h4>
        </div>
    );
}
