import React, { useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import ItemCategoryTree from "../../ItemCategory/Components/ItemCategoryTree";

const SelectedIndexInitState = [""];
const SelectedCategoryInitState = undefined;

const ItemCategoryModal = ({
    visible,
    close,
    item,
    formRenderProps,
    title = "Change Category",
}) => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(SelectedIndexInitState);
    const [selectedCategory, setSelectedCategory] = useState(
        SelectedCategoryInitState
    );

    const onClose = () => {
        setLoaderVisible(false);
        setSelectedCategory(SelectedCategoryInitState);
        setSelectedIndex(SelectedIndexInitState);
        close();
    };

    const onSubmit = () => {
        setLoaderVisible(true);
        formRenderProps.onChange("category", {
            value: selectedCategory,
        });
        onClose();
    };

    const onCategoryClick = (e) => {
        if (selectedCategory !== e.item.data) {
            setSelectedIndex([e.itemHierarchicalIndex]);
            setSelectedCategory(e.item.data);
        }
    };

    return (
        visible && (
            <Form
                initialValues={item}
                render={() => (
                    <ResponsiveDialog
                        title={title}
                        onClose={onClose}
                        size={"medium"}
                    >
                        <FormElement>
                            <ItemCategoryTree
                                select={selectedIndex}
                                onItemClick={onCategoryClick}
                                selectedItem={selectedCategory}
                                showToolbar={false}
                            />
                            <FormButtons
                                onSubmit={onSubmit}
                                actionOnCancel={onClose}
                                loaderVisible={loaderVisible}
                                allowSubmit={true}
                                theme={"primary"}
                                icon={"k-icon k-i-plus"}
                                text={"Change"}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};
export default ItemCategoryModal;
