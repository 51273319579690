import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CustomerContactModal from "./CustomerContactModal";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { updateCustomer } from "../../../../services/Deprecated/customer/customerServices";
import { ActionButton } from "../../../../components/Buttons/ActionButton";

const CustomerContactDetails = () => {
    const customerContext = useContext(CustomerContext);
    const { reloadData, triggerReload } = useContext(ReloadDataContext);
    const [contacts, setContacts] = useState([]);
    const modal = useModal();

    useEffect(() => {
        if (customerContext.customer) {
            const { primaryContact, secondaryContact, otherContacts } =
                customerContext.customer;
            const allContacts = [];

            if (primaryContact) {
                allContacts.push({ ...primaryContact, contactType: "Primary" });
            }

            if (secondaryContact) {
                allContacts.push({
                    ...secondaryContact,
                    contactType: "Secondary",
                });
            }

            if (Array.isArray(otherContacts) && otherContacts.length > 0) {
                otherContacts.forEach((contact) => {
                    allContacts.push({ ...contact, contactType: "Other" });
                });
            }

            setContacts(allContacts);
        }
    }, [customerContext.customer, reloadData]);

    const onDelete = (id) => {
        const record = customerContext.customer;
        // Combine primary, secondary, and other contacts into a single array
        const contacts = [];

        // Handle primary contact formatting (ensure only one primary contact is added)
        if (record.primaryContact) {
            const formattedPrimaryContact = {
                ...record.primaryContact,
                phone: record?.primaryContact?.phone
                    ? {
                          phoneNumber:
                              record.primaryContact.phone?.phoneNumber || "",
                          typeId:
                              record.primaryContact.phone?.phoneNumberType
                                  ?.id || null, // Ensure valid typeId
                      }
                    : undefined,
            };
            contacts.push(formattedPrimaryContact);
        }

        // Handle secondary contact formatting (ensure only one secondary contact is added)
        if (record.secondaryContact) {
            const formattedSecondaryContact = {
                ...record.secondaryContact,
                phone: record?.secondaryContact?.phone
                    ? {
                          phoneNumber:
                              record.secondaryContact.phone?.phoneNumber || "",
                          typeId:
                              record.secondaryContact.phone?.phoneNumberType
                                  ?.id || null, // Ensure valid typeId
                      }
                    : undefined,
            };
            contacts.push(formattedSecondaryContact);
        }

        // Handle other contacts formatting (ensure only unique contacts are added)
        if (Array.isArray(record.otherContacts)) {
            record.otherContacts.forEach((contact) => {
                const formattedOtherContact = {
                    ...contact,
                    phone: contact.phone
                        ? {
                              phoneNumber: contact.phone?.phoneNumber || "",
                              typeId:
                                  contact.phone?.phoneNumberType?.id || null, // Ensure valid typeId
                          }
                        : undefined,
                };
                contacts.push(formattedOtherContact);
            });
        }

        // Filter out the contact to delete
        const updatedContacts = contacts.filter((contact) => contact.id !== id);

        // Construct the updated customer object with the new contacts array
        const updatedCustomer = {
            ...record,
            contacts: updatedContacts,
        };

        // Call the actual API to update the customer record with the updated contacts
        updateCustomer(updatedCustomer)
            .then((response) => {
                if (response.success) {
                    triggerReload();
                }
            })
            .catch((error) => {
                console.error("Error updating customer:", error);
            });
    };
    return customerContext.customer?.id ? (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <span style={{ marginLeft: "10px" }}>
                    <CustomerContactModal {...modal} />
                    <ActionButton
                        onClick={() => modal.open()}
                        text={"Add New Contact"}
                        icon={"k-icon k-i-plus"}
                        requiredPermissions={[PermissionsEnum.CreateCustomer]}
                    />
                </span>
                {contacts.length > 0 ? (
                    <Grid
                        className="card-container"
                        data={contacts}
                        style={{ width: "auto", maxHeight: "400px" }}
                    >
                        <GridColumn field="name1" title="First" />
                        <GridColumn field="name2" title="Last" />
                        <GridColumn field="email" title="Email" />
                        <GridColumn
                            field="phone.phoneNumber"
                            title="Phone Number"
                        />
                        <GridColumn field="label" title="Title" />
                        <GridColumn field="contactType" title="Contact Type" />
                        <GridColumn
                            width={140}
                            filterable={false}
                            sortable={false}
                            cell={(props) => (
                                <ActionCell
                                    onEdit={() => modal.open(props.dataItem.id)}
                                    onDelete={
                                        contacts.length !== 1 &&
                                        (() => onDelete(props.dataItem.id))
                                    }
                                    permissions={{
                                        edit: [PermissionsEnum.EditCustomer],
                                        delete: [PermissionsEnum.EditCustomer],
                                    }}
                                />
                            )}
                        />
                    </Grid>
                ) : (
                    <CenterLoader />
                )}
            </div>
        </>
    ) : null;
};

export default CustomerContactDetails;
