import React, { useContext, useEffect } from "react";
import { Card } from "@progress/kendo-react-layout";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteItem } from "../../../services/Deprecated/inventory/itemService";
import ItemRelationshipsProvider, {
    ItemRelationshipsContext,
} from "../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { useHistory } from "react-router-dom";
import ItemVendorsGrid from "./Components/ItemVendorsGrid";
import ItemTabs from "./Components/ItemTabs";
import itemEditForm from "./Components/ItemEditForm";
import "../../../styles/CommonPositionalStyles.scss";
import { ItemInventoryInformation } from "./Components/ItemInventoryInformation/ItemInventoryInformation";
import { ActionButton } from "../../../components/Buttons/ActionButton.jsx";

const ItemDetailsPageWrapper = (props) => {
    return (
        <ItemRelationshipsProvider>
            <ItemDetailsPage {...props} />
        </ItemRelationshipsProvider>
    );
};

const ItemDetailsPage = (props) => {
    const itemContext = useContext(ItemRelationshipsContext);
    const itemId = props?.match?.params?.id;

    useEffect(() => {
        itemContext.setItemId(itemId);
    }, [itemId]);

    const history = useHistory();

    const gotoItems = () => {
        history.push(`/inventory/item`);
    };
    const handleDeleteSuccess = (itemId) => {
        deleteItem(itemId)
            .then(() => {
                itemContext.setItemId(null);
                // Redirect to the item dashboard page after successful deletion
                history.push(`/inventory/item`);
            })
            .catch((error) => {
                console.error("Error deleting item:", error);
            });
    };

    return itemContext.item?.id ? (
        <div>
            <header className="vendor-name-header">
                {itemContext.item.name}
            </header>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card
                        className="option-card-container"
                        style={{ border: "none" }}
                    >
                        <table>
                            <tbody>
                                <tr
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    <td>
                                        <ActionButton
                                            icon={"k-icon k-i-undo"}
                                            requiredPermissions={[
                                                PermissionsEnum.ViewItem,
                                            ]}
                                            onClick={() => gotoItems()}
                                        >
                                            Go To Items
                                        </ActionButton>
                                    </td>
                                    <ActionButtonsCommandCell
                                        record={itemContext.item}
                                        dataId={itemId}
                                        action={handleDeleteSuccess}
                                        EditComponent={itemEditForm}
                                        requiredPermissionsEdit={[
                                            PermissionsEnum.EditItem,
                                        ]}
                                        requiredPermissionsAction={[
                                            PermissionsEnum.DeleteItem,
                                        ]}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <div className="tab-container">
                        <Card
                            className="card-container"
                            style={{ border: "none" }}
                        >
                            <ItemTabs />
                        </Card>
                    </div>
                </div>
            </div>
            <div
                className={"JustifyLeftAlignLeft SmallGap"}
                style={{
                    margin: "0 20px",
                }}
            >
                <span>
                    <ItemVendorsGrid item={itemContext.item} />
                </span>
                <span>
                    <ItemInventoryInformation item={itemContext.item} />
                </span>
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default ItemDetailsPageWrapper;
