import React, { createContext, useRef, useState } from "react";
import RoleService from "../../../../../services/RoleService.js";

export const RoleContext = createContext(null);

const RoleProvider = ({ children }) => {
    const [role, _setRole] = useState(null);
    const [permissions, _setPermissions] = useState([]);
    const roleServiceRef = useRef(new RoleService());

    const setRole = (role) => {
        _setRole(role);

        if (role) {
            fetchRole(role.id);
        } else {
            _setPermissions([]);
        }
    };

    const updatePermissions = (updatingPermissions) => {
        const addFilter = (p) =>
            p.hasPermission && !permissions.some((cp) => cp.id === p.id);
        const rmFilter = (p) =>
            !p.hasPermission && permissions.some((cp) => cp.id === p.id);
        const adding = updatingPermissions.filter(addFilter).map((p) => p.id);
        const removing = updatingPermissions.filter(rmFilter).map((p) => p.id);
        let parsedPermissions = permissions.map((cp) => cp.id);

        if (removing.length > 0) {
            parsedPermissions = parsedPermissions.filter(
                (p) => !removing.includes(p)
            );
        } else {
            parsedPermissions = [...parsedPermissions, ...adding];
        }

        roleServiceRef.current
            .updatePermissions(role.id, parsedPermissions)
            .then((res) => {
                if (res.success) {
                    fetchRole(role.id);
                }
            });
    };

    const setPermissions = (permissions) => {
        roleServiceRef.current
            .updatePermissions(role.id, permissions)
            .then((res) => {
                if (res.success) {
                    fetchRole(role.id);
                }
            });
    };

    const fetchRole = (roleId) => {
        roleServiceRef.current.get(roleId).then((res) => {
            _setPermissions(res.data?.permissions ?? []);
        });
    };

    const values = {
        setRole,
        role,
        permissions,
        updatePermissions,
    };

    return (
        <RoleContext.Provider value={values}>{children}</RoleContext.Provider>
    );
};

export default RoleProvider;
