import React, { useContext, useState, useEffect } from "react";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { useDueDate } from "@/hooks/useDueDate.js";

import styles from "@/pages/Customer/JobDocuments/Components/JobDocumentModal/Styles/JobDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import { Checkbox } from "@progress/kendo-react-inputs";

/**
 * @function BillingTermsDisplay
 * @description Displays information for a Document about it's billing terms
 * @return {React.JSX.Element}
 * @constructor
 */
export const BillingTermsDisplay = ({ isEditable }) => {
    const { formData } = useContext(VendorDocumentModalContext);
    const billingTermModal = useModal();

    /**
     * Retrieve the billing term in different places
     * it could be located.
     */
    let billingTermId = null;

    if (formData?.vendor?.billingTerm?.id) {
        billingTermId = formData.vendor.billingTerm.id;
    }

    if (formData?.vendor?.billingTermId) {
        billingTermId = formData.vendor.billingTermId;
    }

    if (formData?.billingTermId) {
        billingTermId = formData.billingTermId;
    }

    const { dueDateFormatted, billingTerm } = useDueDate({
        billingTermId: billingTermId,
        startDate: (formData?.date || new Date().toISOString())
    });

    return (

        <>
            <BillingTermModal {...billingTermModal} />
            <span
                className={"JustifyLeftAlignLeft"}
                style={{
                    fontSize: 16,
                    color: "#666666",
                }}
            >
                <span>
                    Billing Terms:{" "}

                    {isEditable ? (
                        <span
                            className={`text-overflow ${styles.LinkDisplay}`}
                            onClick={() => {
                                billingTermModal.open(undefined)
                            }}
                        >
                            <i
                                className={"k-icon k-i-pencil"}
                                style={{ marginBottom: 4 }}
                            />{" "}
                            {billingTerm ? billingTerm.name : (<span style={{ color: "red" }}>**Not Provided**</span>)}
                        </span>
                    ) : (
                        <span>{billingTerm && billingTerm.name}</span>
                    )}
                </span>
            </span>
            {dueDateFormatted && (
                <span
                    className={"JustifyLeftAlignLeft"}
                    style={{
                        fontSize: 16,
                        color: "#666666",
                    }}
                >
                    <span>
                        Due Date:{" "}
                        {dueDateFormatted && dueDateFormatted}
                    </span>
                </span>
            )}
        </>
    );
};

export const BillingTermModal = (props) => {
    const { close, visible, record, type } = props;
    const [data, setData] = useState(undefined);
    const [reloadVendorKey, setReloadVendorKey] = useState(Math.random());
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        if (!visible) return;
        const newData = { billingTermId: formData?.billingTermId };
        setData(newData);
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = async () => {

        setFormData({
            ...formData,
            billingTermId: data.billingTermId
        });

        reloadDocumentInformation();
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Change Billing Term"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        url={"/Inventory/BillingTerm/GetAll?"}
                        name={"billingTermId"}
                        component={FormRemoteDropDown}
                        label={"Billing Term:"}
                        style={{ width: "100%" }}
                        value={formData.billingTermId}
                        onChange={(e) => {
                            setData({
                                ...data,
                                billingTermId: e.value.id,
                                isValid: e.value?.id !== undefined,
                            });
                            setReloadVendorKey(Math.random());
                        }}
                    />
                </FormElement>
                {/* <div style={{ marginTop: 10, width: '100%', textAlign: 'left' }}>
                    <Field
                        component={Checkbox}
                        label={"Set this billing term as the default for this vendor."}
                        rounded={"large"}
                        size={"medium"}
                    />
                </div> */}
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    text={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
